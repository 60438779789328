import React, { useEffect, useState } from "react";
// eslint-disable-next-line object-curly-newline
import MetaTags from "react-meta-tags";
// import { Card } from "react-bootstrap";
//i18n
import { withTranslation } from "react-i18next";
import "./PromotionalMaterials.scss";
import { Link } from "react-router-dom";
import { 
    Col,
    Row,
    Card
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetcPlansStart } from "store/plans/actions";
import { showErrorNotification, showSuccessNotification } from "store/actions";
function PromotionalMaterialsCard({ title, number, description, planId }) {
    const dispatch = useDispatch();
    const { clientData } = useSelector(state=>state.Profile);
    const link = `${process.env.REACT_APP_CP_URL}/plan/create?parentRef=${clientData?.recordId}&planId=${planId}`;
    function copyToClipboard() {
        navigator.clipboard.writeText(link).then(function() {
            dispatch(showSuccessNotification("Link copied to clipboard successfully!"));
        }).catch(function(error) {
            dispatch(showErrorNotification(`Error copying link to clipboard: ${error}`));
        });
    }
    return (
        <Card className="promotional-materials__card">
            <button className="promotional-materials__card-content-link" onClick={copyToClipboard}>Link</button>
            <div className="promotional-materials__card-content-wrapper">
                <div className="promotional-materials__card-content">
                    <p className="promotional-materials__card-number">
                        <span className="promotional-materials__card-title--span"></span>
                        {number}
                    </p>
                    <h2 className="promotional-materials__card-title">{title}</h2>
                    
                    <p className="promotional-materials__card-description">{description}</p>

                    <p className="promotional-materials__card-description">{link}</p>
                </div>
            </div>
        </Card>
    );
}


function PromotionalMaterials(props) {
    
    const plansSelector = useSelector(state => state?.planReducer?.plans);
    const { clientData } = useSelector((state) => state?.Profile);
    const dispatch  = useDispatch();
    const loadPlans = (page, limit) => {
        dispatch(
            fetcPlansStart({
                limit,
                page,
                referralClients: clientData?._id,
            })
        );
    };
    
    useEffect(() => {
        loadPlans(1, 100000);
    }, []);

    return (
        <div className="page-content">
            <MetaTags>
                <title>{props.t("Promotional Materials")}</title>
            </MetaTags>
            <div className="promotional-materials">
                <Row>
                    {
                        plansSelector && plansSelector?.map(plan=> (
                            <Col md="4" className="mb-3" key={plan?._id}>
                                <PromotionalMaterialsCard
                                    title={plan?.title}
                                    planId={plan?._id}
                                    number={plan?.title}
                                    description={plan?.description}
                                />
                            </Col>
                        ))
                    }
                </Row>
            </div>
        </div>
    );
}
export default withTranslation()(PromotionalMaterials);
