import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AvForm, AvGroup, AvRadioGroup, AvRadio, AvField, AvFeedback,
} from "availity-reactstrap-validation";
import {
  Row, Col,
  Modal, ModalHeader, ModalBody,
  Input, Label, Button,
} from "reactstrap";
import { withTranslation } from "react-i18next";

import {
  createAccount, toggleCurrentModal, getAccountTypesStart, createAccountRequest,
} from "store/actions";
import config from "config";

const CreateLiveAccModal = ({ isOpen, toggle, type, t }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAccountTypesStart({
      forCp: true,
    }));
    return () => {
      dispatch(getAccountTypesStart());
    };
  }, []);

  const { clientData } = useSelector((state) => state.Profile);
  console.log("Client Dataaa", clientData._id);


  const { accountTypes, submitting, accounts } = useSelector((state) => state.forex.accounts);
  const [filteredAccountTypes, setFilteredAccountTypes] = useState([]);
  const [platformAccountTypes, setPlatformAccountTypes] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [accountCurrency, setSAccountCurrency] = useState([]);
  const [accountLeverages, setAccountLeverages] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [filteredAccounts, setFilteredAccounts] = useState([]);

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      setFilteredAccounts(accounts.filter(acc => acc.type.toUpperCase() === type.toUpperCase()));
    }
  }, [accounts]); 

  useEffect(() => {
    if (!accountLeverages) {
      setAccountLeverages(config.LEVERAGES);
    }
  }, [accountLeverages]);

  const merchant = localStorage.getItem("PORTAL");
  useEffect(() => {
    if (merchant === "MERCHANT") {
      setFilteredAccountTypes(accountTypes?.filter((at) => at.type === "MERCHANT"));
    } else {
      setFilteredAccountTypes(accountTypes?.filter((at) => at.type === type.toUpperCase()));
    }
  }, [accountTypes, type]);

  // get unique platforms
  const platforms = accountTypes.length > 0 && [...new Set(accountTypes.map(item => item.platform))];
  // filter accounts based on platform

  useEffect(() => {
    if (selectedPlatform === "MT5") {
      if (filteredAccounts?.length > 0) {
        setPlatformAccountTypes(filteredAccountTypes?.filter((account) => account.platform === selectedPlatform && account?.currencies?.[0]?.groupPath !== "real\\usdINZ-B1"));
      } else {
        setPlatformAccountTypes(filteredAccountTypes?.filter((account) => account.platform === selectedPlatform && account?.currencies?.[0]?.groupPath === "real\\usdINZ-B1"));
      }
    } else {
      setPlatformAccountTypes(filteredAccountTypes?.filter((account) => account.platform === selectedPlatform));
    }
  }, [selectedPlatform]);

  const confirmPassword = (value, ctx, input, cb) => {
    if (value !== ctx.password) cb("Password doesn't match!");
    else cb(true);
  };

  const isApprovalRequired = filteredAccounts?.length >= config.MAX_UNAPPROVED_ACCOUNTS && filteredAccounts.length < 3;

  const handleValidSubmit = (event, values) => {
    delete values.confirm_password;
    if (isApprovalRequired) {
      dispatch(createAccount({
        ...values,
      }));
    } else {


      dispatch(createAccountRequest({
        customerId: clientData._id,
        ...values,
      }));
    }
  };

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4" className="text-capitalize">
        {t(`Create ${type} Account`)}
      </ModalHeader>
      <ModalBody className="px-4">
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v);
          }}
        >
          {platforms && <Row className="border rounded-3 p-3">
            <h5>{t("Platforms")}</h5>
            {platforms.map((platform) =>
              <Col key={platform} md="6" className="gy-3">
                <div className="custom-radio"
                  onClick={() => {
                    setSelectedPlatform(platform);
                    document.getElementById(platform).click();
                  }} style={{
                    border: 0,
                  }}>
                  <Input
                    className="mt-0 me-2"
                    id={platform}
                    name="platform"
                    type="radio"
                    value={platform}
                  >
                  </Input>
                  <Label check for={platform}>
                    {t(platform)}
                  </Label>
                </div>
              </Col>)}
          </Row>
          }
          {
            selectedPlatform &&
            <Row className="border rounded-3 p-3 my-1">
              <AvRadioGroup name="accountTypeId" errorMessage={t("Account type is required")} required className="radio-group"  >
                <Row className="radio-group" >
                  <h5>{t("Account Type")}</h5>
                  {platformAccountTypes.length > 0 && platformAccountTypes.map((type) =>
                    <Col key={type._id} md="6" className="gy-3 " >
                      <div className="custom-radio"
                        onClick={() => {
                          setSAccountCurrency(type.currencies);
                          setAccountLeverages(type.leverages);
                          document.getElementById(`radio-accountTypeId-${type._id}`).click();
                        }}  >
                        <AvRadio label={t(type.title)} value={type._id} />
                      </div>
                    </Col>
                  )}
                </Row>
              </AvRadioGroup>
            </Row>}
          {accountCurrency.length > 0 &&
            <Row className="border rounded-3 p-3 my-1">
              <AvRadioGroup name="currency" errorMessage={t("Currency is required")} required className="radio-group" >
                <Row className="radio-group " >
                  <h5 className="mb-3">{t("Account Currency")}</h5>
                  {accountCurrency.map((currency) =>
                    <Col key={currency.currency} md="6" className="gy-3" >
                      <div className="custom-radio"
                        onClick={() => {
                          document.getElementById(`radio-currency-${currency.currency}`).click();
                        }} >
                        <AvRadio label={t(currency.currency)} value={currency.currency} />
                      </div>
                    </Col>
                  )}
                </Row>
              </AvRadioGroup>
            </Row>}
          <Row className="border rounded-3 p-3 mt-3">
            <h5 className="mb-3">{t("Select Leverage")}</h5>
            <AvField type="select" name="leverage" value={"1"} required>
              {accountLeverages?.map((leverage) =>
                <option key={leverage} value={leverage}>{`1:${leverage}`}</option>
              )}
            </AvField>
          </Row>
          {
            isApprovalRequired ?
              <Row className="border rounded-3 p-3 mt-3">
                <Col md="6" className="position-relative">
                  <AvGroup>
                    <Label for="password" className="mb-2">{t("Trading Account Password")}</Label>
                    <AvField name="password" type={showPassword ? "text" : "password"} id="password"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Password is required"
                        },
                        pattern: {
                          errorMessage: "Password must contain at least 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Character",
                          value: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])"
                        },
                        minLength: {
                          value: 8,
                          errorMessage: "Password must be more than 8 characters"
                        },
                      }} />
                    <button className="btn" type="button" onClick={() => { setShowPassword(!showPassword) }} style={{
                      position: "absolute",
                      top: "33px",
                      right: "10px",

                    }}>
                      <i className="mdi mdi-eye-outline"></i>
                    </button>
                  </AvGroup>
                </Col>
                <Col md="6" className="position-relative">
                  <AvGroup>
                    <Label for="confirm_password" className="mb-2">{t("Confirm Password")}</Label>
                    <AvField name="confirm_password" type={showPasswordConfirm ? "text" : "password"} id="confirm_password"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Password is required"
                        },
                        custom: confirmPassword
                      }}
                    />
                    <button className="btn" type="button" onClick={() => { setShowPasswordConfirm(!showPassword) }} style={{
                      position: "absolute",
                      top: "33px",
                      right: "10px",

                    }}>
                      <i className="mdi mdi-eye-outline"></i>
                    </button>
                    <AvFeedback>{t("Password doesn't match")}</AvFeedback>
                  </AvGroup>
                </Col>
              </Row>
              : null
          }
          {
            !isApprovalRequired ?
              <Row className="border rounded-3 p-3 mt-3">
                <AvGroup>
                  <Label for="reason" className="mb-2">{t("Reason for new Account")}</Label>
                  <AvField name="reason" type="reason" id="reason"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Reason is required"
                      },
                      minLength: {
                        value: 7,
                        errorMessage: "Reason must have at least 6 characters"
                      },
                    }} />
                </AvGroup>
              </Row>
              : null
          }
          <div className="text-center mt-3 mb-1">
            <Button type="submit" disabled={submitting} className="color-bg-btn border-0 text-white w-25">{t("Submit")}</Button>
            <Button color="light" type="button" className="w-25 ms-3"
              onClick={() => dispatch(toggleCurrentModal(""))}
            >{t("Skip")}</Button>
          </div>
        </AvForm>
      </ModalBody>
    </Modal >
  );
};

export default withTranslation()(CreateLiveAccModal);