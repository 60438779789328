import axios from "axios";
import * as axiosHelper from "./api_helper";
import qs from "qs";

export const getWallets = async () => {
  const data = await axiosHelper.get("/wallets");
  if (data.isError) {
    throw new Error(data.isError);
  }
  return data?.result;
};

export const getFxDeposits = async (params) => {
  const data = await axiosHelper.get(`/fxtransactions/deposits?${qs.stringify(params)}`);
  if (data.isError) {
    throw new Error(data.isError);
  }
  return data?.result;
};
export const addDeposit = async (values) => {
  // const { id, values } = payload;
  const data = await axiosHelper.postFormData("transactions/deposit/", values);
  // if (data.isError) {
  //   throw new Error(data.message);
  // }
  return data;
};
export const addForexDeposit = async (values) => {
  const data = await axiosHelper.postFormData("fxtransactions/deposits", values);
  return data;
};
// export const fetchwallets = async () => {
//     const data = await axiosHelper.get('wallets');
//     return data.result;
// }

export const paymentGatewayDeposit = async (values, paymentGateway) => {
  let url = "";
  switch (paymentGateway) {
    case "OLX_FOREX":  
      url = "psp/olxforex/pay";
      break;
    case "PRAXIS":
        url = "psp/praxis/pay";
      break;
    case "FINITIC_PAY": 
      url = "psp/finitic-pay/pay";
      
  }
  const data = await axiosHelper.post(url, values);
  return data;
};

export const getFiniticPayFeesConfig = async () => {
  const data = await axiosHelper.get("/psp/finitic-pay/fees");
  return data;
};

export const cancelFiniticPay = async (url) => {
  try {
    console.log("url", url?.replace("/payment/address", "/cancel"));
    // repalce /payment with /cancel
    const data = await axios.get(url?.replace("/payment/address", "/cancel"));
    if (data.status === 200) {
      return true;
    }
    throw new Error("Not able to cancel");
  } catch (error) {
    return false;
  }
};
