import React, { useEffect, useState } from "react";
// eslint-disable-next-line object-curly-newline
import MetaTags from "react-meta-tags";
import { Tab, Tabs } from "react-bootstrap";
import { ReactComponent as CopyIcon } from "assets/img/icons/copy-icon.svg";
//i18n
import { withTranslation } from "react-i18next";
import "./ReferAFriend.scss";
import Statistics from "./Statistics";
import { Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { showErrorNotification, showSuccessNotification } from "store/actions";
import PromotionalMaterials from "./PromotionalMaterials";
function ReferAFriend(props) {
    const [currentTab, setCurrentTab] = useState("statistics");
    const { clientData } = useSelector(state=>state.Profile);
    const dispatch = useDispatch();

    const handleSelect = (key) => {
      setCurrentTab(key);
    };
    function copyToClipboard() {
      navigator.clipboard.writeText(`${process.env.REACT_APP_CP_URL}/register?parentRef=${clientData?.recordId}`).then(function() {
        dispatch(showSuccessNotification("Refer Link copied to clipboard successfully!"));
      }).catch(function(error) {
        dispatch(showErrorNotification(`Error copying Refer Link to clipboard: ${error}`));
      });
    }
    return (
        <div className="page-content">
            <MetaTags>
                <title>{props.t("Dashboard")}</title>
            </MetaTags>
            <Row>
              <Container>
                <div className="refer">
                  <div className="refer-header">
                    <h1 className="refer-header__title">{props.t("Refer a Friend")}</h1>
                    <button className="refer-header__button" onClick={copyToClipboard}>
                      <CopyIcon/>
                      {props.t("Copy Referral Link")}
                    </button>
                  </div>
                    <Tabs
                      defaultActiveKey="statistics"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                      onSelect={handleSelect}
                      activeKey={currentTab}
                    >
                        <Tab eventKey="statistics" title="Statistics">
                          <Statistics 
                              // setIsPublic={setIsPublic}
                          />
                        </Tab>
                        <Tab eventKey="promotionalMaterials" title="Promotional Materials">
                          <PromotionalMaterials 
                              // setIsPublic={setIsPublic}
                          />
                        </Tab>
                    </Tabs>
                </div>
              </Container>
            </Row>
        </div>
    );
}
export default withTranslation()(ReferAFriend);
