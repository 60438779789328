import React, { useEffect, useState } from "react";
// eslint-disable-next-line object-curly-newline
import { Button, Col, Container, Label, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import MetaTags from "react-meta-tags";

// import { fetchWallets, toggleCurrentModal } from "src/store/actions";
//i18n
import { withTranslation } from "react-i18next";
import CardWrapper from "components/Common/CardWrapper";
import CustomTable from "components/Common/CustomTable";
import { getAssetImgSrc } from "helpers/assetImgSrc";
import { fetchWallets, toggleCurrentModal } from "store/actions";
import Loader from "components/Common/Loader";
import { JClickHandler } from "components/Journey/handlers";
import "./CreateChallenge.scss";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { addPlanStart, fetcPlansStart } from "store/plans/actions";

function FreePlanForm(props) {
  const {
    plan,
    planType,
    platform,
    tradingCapital,
    preference,
    setPlanType,
    setPlatform,
    setTradingCapital,
    setPreference,
    setIsFree,
  } = props;
  const dispatch = useDispatch();
  const { addButtonDisabled, addSuccess } = useSelector((state) => state.planReducer);
  const { clientData } = useSelector((state) => state?.Profile);
  
  const handleAddPlan =  (e) => {
    e.preventDefault();
    // const formData = new FormData();
    const formData = new FormData();
    formData.append("title", "Free Trial Phase");
    formData.append("description", "Free Trial Phase");
    formData.append("price", 0);
    formData.append("initialBalance", 0);
  
    planType === "2" && formData.append("leverage", 100);
    formData.append("planType", planType);
    formData.append("isFree", true);
    formData.append("platforms", platform);
    formData.append("tradingCapital", tradingCapital);
    formData.append("preference", preference);
    
    [...Array(Number(planType))]?.forEach((phase, index)=>{
      formData.append(`phases[${index}][title]`, `Phase ${index + 1}`);
      formData.append(`phases[${index}][inactiveDays]`, 10000);
      formData.append(`phases[${index}][targetDailyLoss]`, tradingCapital);
      formData.append(`phases[${index}][targetMaxLoss]`, tradingCapital);
      formData.append(`phases[${index}][targetProfitLoss]`, tradingCapital);
      formData.append(`phases[${index}][targetMinTradingDays]`, 2);
      formData.append(`phases[${index}][targetMaxTradingDays]`, 1000);
      formData.append(`phases[${index}][targetMaxPositions]`, 10000);
      formData.append(`phases[${index}][targetMaxLots]`, 10000);
    });

    dispatch(addPlanStart(formData));
  };
  useEffect(()=>{
    if (clientData?.hasFreeTrial) {
      setIsFree(false);
    }
  }, [clientData]);
  useEffect(()=>{
    setPlanType("2");
  }, []);
  return (
    <Row>
      <Col lg="12">
        <CardWrapper className="glass-card shadow create-challenge-plan-options-card">
          <h2 className="create-challenge-plan-card-options-card-title">{props.t("Select Plan Type")}</h2>
          <div className="create-challenge-plan-options-wrapper">
            
            {/* <div className={`create-challenge-plan-option-wrapper ${planType === "1" ? "create-challenge-plan-option-wrapper--active" : ""}`}  onClick={()=>setPlanType("1")}>
              <input 
                type="checkbox"
                className="create-challenge-plan-option-input"
                name="planType"
                value="1"
                id="planType1"
                checked={planType === "1"}
                onClick={()=>setPlanType("1")}
              />
              <Label htmlFor="planType1" className="create-challenge-plan-option-label">{props.t("1-Step")}</Label>
            </div> */}
            
            <div className={`create-challenge-plan-option-wrapper ${planType === "2" ? "create-challenge-plan-option-wrapper--active" : ""}`}  onClick={()=>setPlanType("2")}>
              <input 
                type="checkbox"
                className="create-challenge-plan-option-input"
                name="planType"
                value="2"
                id="planType2"
                checked={planType === "2"}
                onClick={()=>setPlanType("2")}
              />
              <Label htmlFor="planType2" className="create-challenge-plan-option-label">{props.t("2-Step")}</Label>
            </div>
          </div>
          
        </CardWrapper>
        <CardWrapper className="glass-card shadow create-challenge-plan-options-card">
          <h2 className="create-challenge-plan-card-options-card-title">{props.t("Select Your Trading Capital")}</h2>
          <div className="create-challenge-plan-options-wrapper">
            {
              planType === "1" && 
                <div className={`create-challenge-plan-option-wrapper ${tradingCapital === "5000" ? "create-challenge-plan-option-wrapper--active" : ""}`} onClick={()=>setTradingCapital("5000")}>
                  <input 
                    type="checkbox"
                    className="create-challenge-plan-option-input"
                    name="tradingCapital"
                    value="tradingCapital5000"
                    id="tradingCapital5000"
                    checked={tradingCapital === "5000"}
                    onClick={()=>setTradingCapital("5000")}
                  />
                  <Label htmlFor="tradingCapital5000" className="create-challenge-plan-option-label">${props.t("5,000")}</Label>
                </div>
            }
            
            <div className={`create-challenge-plan-option-wrapper ${tradingCapital === "10000" ? "create-challenge-plan-option-wrapper--active" : ""}`} onClick={()=>setTradingCapital("10000")}>
              <input 
                type="checkbox"
                className="create-challenge-plan-option-input"
                name="tradingCapital"
                value="tradingCapital10000"
                id="tradingCapital10000"
                checked={tradingCapital === "10000"}
                onClick={()=>setTradingCapital("10000")}
              />
              <Label htmlFor="tradingCapital10000" className="create-challenge-plan-option-label">${props.t("10,000")}</Label>
            </div>
            {
              planType === "1" && 
              <>
                <div className={`create-challenge-plan-option-wrapper ${tradingCapital === "25000" ? "create-challenge-plan-option-wrapper--active" : ""}`} onClick={()=>setTradingCapital("25000")}>
                  <input 
                    type="checkbox"
                    className="create-challenge-plan-option-input"
                    name="tradingCapital"
                    value="tradingCapital25000"
                    id="tradingCapital25000"
                    checked={tradingCapital === "25000"}
                    onClick={()=>setTradingCapital("25000")}
                  />
                  <Label htmlFor="tradingCapital25000" className="create-challenge-plan-option-label">${props.t("25,000")}</Label>
                </div>
                
                <div className={`create-challenge-plan-option-wrapper ${tradingCapital === "50000" ? "create-challenge-plan-option-wrapper--active" : ""}`} onClick={()=>setTradingCapital("50000")}>
                  <input 
                    type="checkbox"
                    className="create-challenge-plan-option-input"
                    name="tradingCapital"
                    value="tradingCapital50000"
                    id="tradingCapital50000"
                    checked={tradingCapital === "50000"}
                    onClick={()=>setTradingCapital("50000")}
                  />
                  <Label htmlFor="tradingCapital50000" className="create-challenge-plan-option-label">${props.t("50,000")}</Label>
                </div>
                
                <div className={`create-challenge-plan-option-wrapper ${tradingCapital === "100000" ? "create-challenge-plan-option-wrapper--active" : ""}`} onClick={()=>setTradingCapital("100000")}>
                  <input 
                    type="checkbox"
                    className="create-challenge-plan-option-input"
                    name="tradingCapital"
                    value="tradingCapital100000"
                    id="tradingCapital100000"
                    checked={tradingCapital === "100000"}
                    onClick={()=>setTradingCapital("100000")}
                  />
                  <Label htmlFor="tradingCapital100000" className="create-challenge-plan-option-label">${props.t("100,000")}</Label>
                </div>
              </>
            }
          </div>
        </CardWrapper>
        <CardWrapper className="glass-card shadow create-challenge-plan-options-card">
          <h2 className="create-challenge-plan-card-options-card-title">{props.t("Select Your Platform")}</h2>
          <div className="create-challenge-plan-options-wrapper">
            
            {/* <div className={`create-challenge-plan-option-wrapper ${platform === "MT4" ? "create-challenge-plan-option-wrapper--active" : ""}`} onClick={()=>setPlatform("MT4")}>
              <input 
                type="checkbox"
                className="create-challenge-plan-option-input"
                name="platform"
                value="metaTrader"
                id="platformMT4"
                checked={platform === "MT4"}
                onClick={()=>setPlatform("MT4")}
              />
              <Label htmlFor="platformMT4" className="create-challenge-plan-option-label">{props.t("MT4")}</Label>
            </div> */}
            <div className={`create-challenge-plan-option-wrapper ${platform === "MT5" ? "create-challenge-plan-option-wrapper--active" : ""}`} onClick={()=>setPlatform("MT5")}>
              <input 
                type="checkbox"
                className="create-challenge-plan-option-input"
                name="platform"
                value="MT5"
                id="platformMT5"
                checked={platform === "MT5"}
                onClick={()=>setPlatform("MT5")}
              />
              <Label htmlFor="platformMT5" className="create-challenge-plan-option-label">{props.t("MT5")}</Label>
            </div>
          </div>
          
        </CardWrapper>
        {
          planType === "2" && 
            <CardWrapper className="glass-card shadow create-challenge-plan-options-card">
              <h2 className="create-challenge-plan-card-options-card-title">{props.t("Select Your Leverage")}</h2>
              <div className="create-challenge-plan-options-wrapper">
                
                <div className={"create-challenge-plan-option-wrapper create-challenge-plan-option-wrapper--active"}>
                  <input 
                    type="checkbox"
                    className="create-challenge-plan-option-input"
                    name="leverage"
                    value="metaTrader"
                    id="leverage"
                    checked={true}
                  />
                  <Label htmlFor="leverage" className="create-challenge-plan-option-label">100</Label>
                </div>
              </div>
              
            </CardWrapper>
        }

        {/* <CardWrapper className="glass-card shadow create-challenge-plan-options-card">
          <h2 className="create-challenge-plan-card-options-card-title">{props.t("Select Your Preference")}</h2>
          <div className="create-challenge-plan-options-wrapper">
            <div className={`create-challenge-plan-option-wrapper ${preference === "swap" ? "create-challenge-plan-option-wrapper--active" : ""}`} onClick={()=>setPreference("swap")}>
              <input 
                type="checkbox"
                className="create-challenge-plan-option-input"
                name="preference"
                value="swap"
                id="preferenceSwap"
                checked={preference === "swap"}
                onClick={()=>setPreference("swap")}
              />
              <Label htmlFor="preferenceSwap" className="create-challenge-plan-option-label">{props.t("Swap")}</Label>
            </div>
            <div className={`create-challenge-plan-option-wrapper ${preference === "swapFree" ? "create-challenge-plan-option-wrapper--active" : ""}`}>
              <input 
                type="checkbox"
                className="create-challenge-plan-option-input"
                name="preference"
                value="swapFree"
                id="preferenceSwapFree"
                checked={preference === "swapFree"}
                onClick={()=>setPreference("swapFree")}
              />
              <Label htmlFor="preferenceSwapFree" className="create-challenge-plan-option-label">{props.t("Swap Free(Price+10%)")}</Label>
            </div>
          </div>
          
        </CardWrapper> */}
        
        <div className="create-challenge-plan__total-wrapper">
          <button
            disabled={addButtonDisabled}
            onClick={(e)=>handleAddPlan(e)} 
            type="submit"
            className="create-challenge-plan__enroll"
          >
            {
              !addButtonDisabled ?  props.t("Enroll Now")
                : <Loader />
            }
          </button>
          {/* <button type="button" onClick={(e)=>handleAddPlan(e)} className="create-challenge-plan__enroll">{props.t("Enroll Now")}</button> */}
        </div>
      </Col>
      
      {/* <Col lg="6">
      </Col> */}
    </Row>
  );
}
export default withTranslation()(FreePlanForm);
