import CustomPagination from "components/Common/CustomPagination";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
} from "reactstrap";
import { fetchNotifications } from "store/actions";
import { useTranslation } from "react-i18next";
import CardWrapper from "components/Common/CardWrapper";
import Loader from "components/Common/Loader";
import { getFxDeposits } from "apis/deposit";

function BillingsList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [billings, setBillings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  // const {
  //   notificationsData,
  //   loading,
  // } = useSelector(
  //   (state) => ({
  //     notificationsData: state.notificationsReducer?.notifications || [],
  //     loading: state.notificationsReducer?.loading || false,
  //     markReadLoading: state.notificationsReducer?.markReadLoading || false,
  //   })
  // );
  // const {
  //   docs: notifications,
  // } = notificationsData;

  // const fetchData = (page = 1) => {
  //   dispatch(fetchNotifications({
  //     page,
  //     limit,
  //   }));
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   fetchData(1);
  // }, [limit, 1]);

  const fetchData = (page = 1, limit = 10) => {
    setLoading(true);
    getFxDeposits({
      page,
      limit
    }).then((res) => {
        setBillings(res);
        setLoading(false);
        // setResult(res);
        // toggleTab(1);
    }).catch((err) => {
        setLoading(false);
        // setResult(err);
        // toggleTab(1);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData(1, limit);
  }, [limit, 1]);

  function dateFormatter(val) {
    let d = new Date(val.createdAt);
    d = `${d.toLocaleDateString()}, ${d.toLocaleTimeString()}`;
    return d;
  }

  function renderBillingsRows() {
    return billings?.docs?.map((billing, index) =>
      <tr key={index} className="border-top">
        <td className="text-start">{billing.recordId}</td>
        <td className="text-start">{billing.gateway}</td>
        <td className="text-start">{billing.plan?.title}</td>
        <td className="text-start">{dateFormatter(billing)}</td>
        <td className="text-start">{billing.recordId}</td>
        <td className="text-start">{billing.type}</td>
        <td className="text-start">{billing.amount}</td>
        {/* <td className="text-start">{billing.body}</td> */}
        <td className={`text-start ${billing.status === "APPROVED" ? "text-success" : billing.status === "REJECTED" ? "text-danger" : "text-warning"}`}>{billing.status}</td>

        {/* <td className="text-start">{billing.isApproving ? "true" : "false"}</td> */}
      </tr>
    );
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          {t("Billings")}
        </title>
      </MetaTags>
      <div>
        <CardWrapper className="p-4 glass-card shadow">
          <div className="border rounded-3 mt-4">
            <Table borderless responsive className="text-center mb-0" >
              <thead>
                <tr className="color-primary">
                  <th >{t("SN")}</th>
                  <th >{t("Payment Method")}</th>
                  <th >{t("Plan")}</th>
                  <th >{t("Date")}</th>
                  <th >{t("Transaction ID")}</th>
                  <th >{t("Transition Type")}</th>
                  <th >{t("Paid Amount")}</th>
                  <th >{t("Status")}</th>
                  {/* <th >{t("Funding Package")}</th> */}
                  {/* <th >{t("Funding Package")}</th> */}
                  {/* <th >{t("Payment Proof")}</th> */}
                </tr>
              </thead>
              <tbody>
                {loading && <tr><td colSpan="100%" className="my-2"><Loader /></td></tr>}
                {!loading && billings.length === 0 && (
                  <tr><td colSpan="100%" className="my-2">{t("No recent Billings.")}</td></tr>
                )}
                {!loading && billings.length !== 0 && renderBillingsRows()}
              </tbody>
            </Table>
          </div>
          <div className="mt-4">
            <CustomPagination
              {...billings}
              docs={billings}
              setSizePerPage={setLimit}
              sizePerPage={limit}
              onChange={fetchData}
            />
          </div>
        </CardWrapper>
      </div>
    </React.Fragment>
  );
}

export default BillingsList;