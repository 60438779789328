import {
  // GENERATE_QR_CODE_START, 
  // GENERATE_QR_CODE_SUCCCESS,
  // GENERATE_QR_CODE_ERROR,
  REQUEST_IB_START,
  REQUEST_IB_SUCCESS,
  REQUEST_IB_FAIL,
  GET_REQUEST_IB_START,
  GET_REQUEST_IB_SUCCESS,
  GET_REQUEST_IB_FAIL,
  // VERIFY_TWO_FACTOR_CODE_SUCCESS,
  // VERIFY_TWO_FACTOR_CODE_FAIL 
} from "./actionTypes";

export const requestIb = (payload) =>{
  return {
    type: REQUEST_IB_START,
    payload
  };
};

export const requestIbSuccess = (payload) =>{
  return {
    type: REQUEST_IB_SUCCESS,
    payload
  };
};

export const requestIbFail = (payload) =>{
  return {
    type: REQUEST_IB_FAIL,
    payload
  };
};

export const getRequestIb = (payload) =>{
  return {
    type: GET_REQUEST_IB_START,
    payload
  };
};

export const getRequestIbSuccess = (payload) =>{
  return {
    type: GET_REQUEST_IB_SUCCESS,
    payload
  };
};

export const getRequestIbFail = (payload) =>{
  return {
    type: GET_REQUEST_IB_FAIL,
    payload
  };
};

// export const generateQRCodeStart = (payload)=>{
//   return {
//     type: GENERATE_QR_CODE_START,
//     payload
//   };
// };

// export const verify2FACodeSuccess = (payload)=>{
//   return {
//     type: VERIFY_TWO_FACTOR_CODE_SUCCESS,
//     payload
//   };
// };

// export const verify2FACodeFail = (payload)=>{
//   return {
//     type: VERIFY_TWO_FACTOR_CODE_FAIL,
//     payload
//   };
// };

// export const generateQRCodeSuccess = (payload)=>{
//   return {
//     type: GENERATE_QR_CODE_SUCCCESS,
//     payload
//   };
// };

// export const generateQRCodeError = (payload)=>{
//   return {
//     type: GENERATE_QR_CODE_ERROR,
//     payload
//   };
// };