import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { 
  Button, Col, Row 
} from "reactstrap";
import { withTranslation } from "react-i18next";
import CustomModal from "components/Common/CustomModal";
import { toggleCurrentModal } from "store/actions";
import { getUserPin } from "apis/register";

function SelectLoginMethod({ isOpen, toggleOpen, ...props }) {
  const [activeStep, setActiveStep] = useState(0);
  const [is2FA, setIs2FA] = useState(false);
  console.log(is2FA);
  useEffect(() => {
    if (isOpen) {
      setActiveStep(0);  
    }
  }, [isOpen]); 
  const dispatch = useDispatch();
  function toggleTab(tab) {
    if (activeStep !== tab) {
      setActiveStep(tab);
    }
  }

    useEffect(async () => {
        try {
        const res = await getUserPin({ email: props.email });
        // console.log(res.result);
        setIs2FA(res.result.settings.twoFactorAuthEnabled);
        
      } catch (error) {
        console.log(error);
      }

    }, []);

  const steps = [
    {
      header: props.t("Select Method"),
      content: (
        <>
          <div>
            {/* text */}
            <Row>
              <h3>
                {props.t("Login")}
              </h3>
              <small>
                {props.t("Select a way to login")}
              </small>
            </Row>
            {!is2FA && (
                <Row>
                  <Button
                    outline 
                    type="button"
                    color="success"
                    className="waves-effect waves-light w-lg m-2 btn-lg"
                    onClick={() => {
                      dispatch(toggleCurrentModal("loginOTP"));
                    }}
                  >
                    <Row>
  
                      <Col md="9" sm="9">
                        <div className="d-flex flex-column text-start">
                          {props.t("OTP")} 
                          <small>{props.t("Login With OTP")}</small>
                        </div>
                      </Col>
  
                      <Col md="1" sm="1" className="align-self-center">
                        <i className="mdi mdi-arrow-right" />
                      </Col>
                    </Row>
                  </Button>
                </Row>
            )}

            {
              is2FA && (<>
                <Row>
                  <Button
                    outline 
                    type="button"
                    color="success"
                    className="waves-effect waves-light w-lg m-2 btn-lg"
                    onClick={() => {
                      dispatch(toggleCurrentModal("TwoFactorAuth", {
                        email: props.email,
                        type: "login",
                    }));
                }}
                  >
                    <Row>

                      <Col md="9" sm="9">
                        <div className="d-flex flex-column text-start">
                          {props.t("2FA")} 
                          <small>{props.t("Login With 2FA")}</small>
                        </div>
                      </Col>

                      <Col md="1" sm="1" className="align-self-center">
                        <i className="mdi mdi-arrow-right" />
                      </Col>
                    </Row>
                  </Button>
                </Row>
              </>)
            }
          </div>
        </>
      ),
    }
  ];

  return (
    <>
      <CustomModal
        steps={steps}
        size="md"
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        activeStep={activeStep}
        toggleTab={toggleTab}
      ></CustomModal> 
    </>
  );
}
export default withTranslation()(SelectLoginMethod); 
