import * as axiosHelper from "./api_helper";

export const getCompetitions = async () => {
  const data = await axiosHelper.get("competitions");
  return data.result;
};

export const subscribeToCompetition = async (login, comp) => {
  const data = await axiosHelper.post("competitions/subscribe", {
    login: login,
    competition: comp._id
  });
  return data;
};

export const unSubscribeToCompetition = async (login, comp) => {
  const data = await axiosHelper.post("competitions/unsubscribe", {
    login: login,
    competition: comp._id
  });
  return data;
};