import CardWrapper from "components/Common/CardWrapper";
import { useEffect, useState } from "react";
import {
    Button,
    CardHeader, Container, Input, Label, Table
} from "reactstrap";
import { fetchCompetitions, showErrorNotification } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { 
    Th, Thead, Tr
 } from "react-super-responsive-table";
import { withTranslation } from "react-i18next";

function Leaderboard(props) {
  const { getCompetitions } = useSelector(state => state.competitionReducer);
  const [selectedComp, setSelectedComp] = useState(props.location.state);
    
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(fetchCompetitions());
    }, []);

  const columns = [
    {
      text: props.t("Rank"),
      formatter: (val) => val.rank + 1,
    },
    {
      dateField: "firstName",
      text: props.t("First Name"),
    },
    {
      dateField: "lastName",
      text: props.t("Last Name"),
    },
    {
      dateField: "totalAmount",
      text: props.t("Total"),
      formatter: (val) => val.totalAmount ? parseFloat(val.totalAmount).toFixed(2) : "--",
    },
  ];

  const { layoutMode } = useSelector(state => state.Layout);
  const customStyles = {
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#495057",
      padding: 0,
      paddingRight: "5px",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none"
    }),
    control: (provided) => {
      if (layoutMode === "dark") {
        return {
          ...provided,
          backgroundColor: "#242632",
          border: 0,
          boxShadow: "0 0.125rem 0.25rem #0B182F",
          color: "#adb5bd",
          height: "100%",
        };
      }
      return {
        ...provided,
      };
    },
    menu: (provided) => ({
      ...provided,
      backgroundColor: layoutMode === "dark" ? "#242632" : "white",
      color: layoutMode === "dark" ? "#adb5bd" : "#495057",
      zIndex: 3,
    }),
    option: (provided, state) => ({
      ...provided,
      display: state.isDisabled ? "none" : "flex",
      flexDirection: "row",
      alignItems: "center",
      color: layoutMode === "dark" ? "#adb5bd" : "#495057",
    }),
    singleValue: (provided) => {
      return {
        ...provided,
        flexDirection: "row",
        alignItems: "center",
        color: layoutMode === "dark" ? "#adb5bd" : "#495057",
      };
    },
  };
  return (
    <>
      <div className="page-content">
        <Container className="mt-5">
          <CardWrapper className="mt-5">
            <CardHeader title="Competitions"></CardHeader>
            <div className="mt-3">
              <div>
                <Select
                  placeholder={props.t("Select competition")}
                  styles= {customStyles}
                  defaultValue={props.location.state?.name ? {
                    label: `${props.location.state?.name} (${props.location.state?.clientType}) (${props.location.state?.type})`,
                    value: props.location.state
                  } : {}}
                  options={getCompetitions.docs?.map((comp) => {
                      return {
                          label: `${comp.name} (${comp.clientType}) (${comp.type})`,
                          value: comp
                      };
                  })}
                  onChange={(val) => {
                      setSelectedComp(val.value);
                  }}
                >
                </Select>
              </div>
              {selectedComp ? (
                <div className="mt-4">
                  <Table borderless responsive className="text-center mb-0">
                    <Thead className="text-center table-light">
                      <Tr>
                        {columns.map((column, index) => (
                          <Th
                            data-priority={index}
                            key={index}
                            className="color-primary"
                          >
                            {column.text}
                          </Th>
                        ))}
                      </Tr>
                    </Thead>
                    <tbody>
                      {selectedComp?.competitionStats?.map((item, rank) => {
                        return (
                          <Tr key={rank}>
                            {columns.map((column, index) => (
                              <td key={index} data-priority={index}>
                                {column.formatter
                                  ? column.formatter({
                                      ...item,
                                      rank,
                                    })
                                  : item[column.dateField]}
                              </td>
                            ))}
                          </Tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <></>
              )}
            </div>
          </CardWrapper>
        </Container>
      </div>
    </>
  );
}

export default withTranslation()(Leaderboard);
