import React, { useEffect, useState } from "react";
// eslint-disable-next-line object-curly-newline
import MetaTags from "react-meta-tags";
//i18n
import { withTranslation } from "react-i18next";
import "./Prizes.scss";
import { Link } from "react-router-dom";
import { 
    Col, Container, Row
} from "reactstrap";
import CardWrapper from "components/Common/CardWrapper";
import { fetchChallenges } from "apis/challenges";
import { companyName } from "content";
import { getAccountsAPI } from "apis/accounts";
function Prizes(props) {
    const [activeFilter, setActiveFilter] = useState("IN_PROGRESS");
    const [progressTypeFilter, setProgressTypeFilter] = useState("LIVE");
    const [plansSubscription, setPlansSubscription] = useState([]);
    const [plansSubscriptionFiltered, setPlansSubscriptionFiltered] = useState([]);
    useEffect(()=>{
        getAccountsAPI({ 
            limit: 1000000000000,
            isPrize: true
        }).then((res) => {
            setPlansSubscription(res?.result?.docs);
            setPlansSubscriptionFiltered(res?.result?.docs);
            
        }).catch((err) => {
            console.log(err);
        });
    }, []);
    // useEffect(()=>{
    //     const planFiltered = plansSubscription?.filter((plan)=>{
    //         if (activeFilter === "IN_PROGRESS") {
    //             return (plan?.status === activeFilter) && (plan?.tradingAccountId?.type === progressTypeFilter);
    //         }
    //         return plan?.status === activeFilter;
    //     });
    //     setPlansSubscriptionFiltered(planFiltered);
    // }, [activeFilter, plansSubscription, progressTypeFilter]);
    return (
        <div className="page-content">
            <MetaTags>
                <title>{props.t("Dashboard")}</title>
            </MetaTags>
            <div className="account-header">
                <div className="account-header__text-wrapper">
                    <h1 className="account-header__text-title">{props.t("Accounts")}</h1>
                    <p className="account-header__text-description">{props.t(`Unlock your trading potential with ${companyName}. Start trading now!`)}</p>
                </div>
                <div className="account-header__buttons-wrapper">
                    <Link to="/plans/create" className="account-header__button-free">{props.t("Free Trial")}</Link>
                    <Link to="/plans/create" className="account-header__button-challenge">{props.t("Start Challenge")}</Link>
                </div>
            </div>

            <div className="account-conetnt">
                <Container>
                    {/* <div className="account-conetnt__filter-wrapper">
                        <button type="button" onClick={()=>setActiveFilter("IN_PROGRESS")} className={`account-conetnt__filter-button ${activeFilter === "IN_PROGRESS" ? "account-conetnt__filter-button--active" : ""}`}>{props.t("Active")}</button>
                        <button type="button" onClick={()=>setActiveFilter("FAIL")} className={`account-conetnt__filter-button ${activeFilter === "FAIL" ? "account-conetnt__filter-button--active" : ""}`}>{props.t("Fail")}</button>
                        <button type="button" onClick={()=>setActiveFilter("SUCCESS")} className={`account-conetnt__filter-button ${activeFilter === "SUCCESS" ? "account-conetnt__filter-button--active" : ""}`}>{props.t("Success")}</button>
                    </div>
                    {
                        activeFilter === "IN_PROGRESS" &&
                            <div className="account-conetnt__filter-wrapper">
                                <button type="button" onClick={()=>setProgressTypeFilter("LIVE")} className={`account-conetnt__filter-button ${progressTypeFilter === "LIVE" ? "account-conetnt__filter-button--active" : ""}`}>{props.t("LIVE")}</button>
                                <button type="button" onClick={()=>setProgressTypeFilter("DEMO")} className={`account-conetnt__filter-button ${progressTypeFilter === "DEMO" ? "account-conetnt__filter-button--active" : ""}`}>{props.t("DEMO")}</button>
                            </div>
                    } */}
                    <Row>
                        <Col lg="8">
                            {
                                plansSubscriptionFiltered && plansSubscriptionFiltered?.map((plan)=>(
                                    <div className="account-conetnt__plans-wrapper" key={plan?._id}>
                                        <h2 className="account-conetnt__plans-title">{plan?.planId?.title}</h2>
                                        <CardWrapper className="mb-5 glass-card shadow account-conetnt__plan-card h-auto">
                                            <div className="account-conetnt__plan-card-div">
                                                <h3 className="account-conetnt__plan-card-title">
                                                    {plan?.title}<span className="account-conetnt__plan-card-title--span">{plan?.planId?.price || ""}</span>
                                                </h3>
                                                <div className="account-conetnt__plan-card-content-wrapper">
                                                    <div className="account-conetnt__plan-card-texts-wrapper">
                                                        <div className="account-conetnt__plan-card-text-wrapper">
                                                            <p className="account-conetnt__plan-card-text">Server Type: {plan?.platform}</p>
                                                            <p className="account-conetnt__plan-card-text">Balance: {plan?.tradingCapital}</p>
                                                        </div>
                                                        <div className="account-conetnt__plan-card-text-wrapper">
                                                            <p className="account-conetnt__plan-card-text">Trading Account Id: {plan?._id}</p>
                                                            <p className="account-conetnt__plan-card-text">Account Type: Prize Account</p>
                                                        </div>
                                                    </div>
                                                    <Link to={`/prizes/accounts/overview/${plan?._id}`} className="account-conetnt__plan-card-link">{props.t("Dashboard")}</Link>
                                                </div>
                                            </div>
                                        </CardWrapper>
                                    </div>
                                ))
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}
export default withTranslation()(Prizes);
