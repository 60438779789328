import { useState, useEffect } from "react";
import { AvField, AvForm } from "availity-reactstrap-validation";
import {
  Button,
  Col,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { useTranslation, withTranslation } from "react-i18next";
import { addForexDeposit } from "apis/deposit";
import { checkForDiscountCoupoun } from "apis/plans";
import { allowedMethods } from "./Methods/allowedMethods";
import WireTransfer from "./Methods/WireTransfer";
import Others from "./Methods/Others";
import CardWrapper from "components/Common/CardWrapper";
import CustomModal from "components/Common/CustomModal";
import StageTwo from "./StageTwo";
import { useDispatch, useSelector } from "react-redux";
import { fetchWallets, getAccountsStart } from "store/actions";
import OlxForex from "./Methods/OlxForex";
import CompanyCrypto from "./Methods/Crypto";
import classnames from "classnames";
import "./Deposit.scss";
import { validateFile } from "helpers/validations/file";

function Deposit({ isOpen, toggleOpen, type, plan, platform, tradingCapital, preference }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Selectors
  const { wallets } = useSelector((state) => state?.walletReducer);
  // States
  const [activeStep, setActiveStep] = useState(0);
  const [gateway, setGateway] = useState("WIRE_TRANSFER");
  const [result, setResult] = useState("");
  const [checkCoupounResult, setCheckCoupounResult] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  // used to check if amount is less than 15 then raise an error
  const [hoveredPaymentMethod, setHoveredPaymentMethod] = useState();
  const [paymentPayload, setPaymentPayload] = useState({});
  const [file, setFile] = useState();

  const [isFirstStepValid, setIsFirstStepValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCheckCoupoun, setLoadingCheckCoupoun] = useState(false);
  const [discountCoupoun, setDiscountCoupoun] = useState("");
  const [priceAfterDiscount, setPriceAfterDiscount] = useState("");
  const [baseCurrency, setBaseCurrency] = useState("USD");
  const targetCurrency = "USD";

  // Effects
  useEffect(() => {
    if (isOpen) {
      setActiveStep(0);
      setGateway("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (type === "mt5Deposit") {
      dispatch(getAccountsStart(
        {
          type: "LIVE",
        },
      ));
    } else if (!wallets) {
      dispatch(fetchWallets());
    }
  }, [type]);

  // Handlers
  function toggleTab(tab) {
    if (activeStep !== tab) {
      setActiveStep(tab);
    }
  }

  const stageOnePaymentDetails = () => {
    switch (gateway) {
      case "WIRE_TRANSFER":
        return (
          <WireTransfer t={t} setIsFirstStepValid={setIsFirstStepValid}
            setBaseCurrency={setBaseCurrency} />
        );
      case "FINITIC_PAY":
      case "OLX_FOREX":
        return (
          <OlxForex t={t} setIsFirstStepValid={setIsFirstStepValid} />
        );
      case "NETELLER":
      case "MASTERCARD":
      case "SKRILL":
        return (
          <Others t={t} setIsFirstStepValid={setIsFirstStepValid} />
        );
      case "CRYPTO":
        return (
          <CompanyCrypto
            t={t} setIsFirstStepValid={setIsFirstStepValid} setPaymentPayload={setPaymentPayload}
            setBaseCurrency={setBaseCurrency} />
        );
      default:
        return (
          <div className="text-center">
            <h5 className="mb-3">
              {t("Please Select Payment Method")}
            </h5>
          </div>
        );
    }
  };

  const submitHandler = (data) => {
      addForexDeposit(data).then((res) => {
        setLoading(false);
        setResult(res);
        toggleTab(1);
      }).catch((err) => {
        setLoading(false);
        setResult(err);
        toggleTab(1);
      });
  };

  const checkForDiscountCoupounFn = () => {
    const discountCouponData = {
      planId: plan?.id,
      discountCoupoun
    };
    setLoadingCheckCoupoun(true);
    checkForDiscountCoupoun(discountCouponData).then((res) => {
      setLoadingCheckCoupoun(false);
      setCheckCoupounResult(res.message);
      setPriceAfterDiscount(res?.result?.newPlanPrice);
    }).catch((err) => {
      setLoadingCheckCoupoun(false);
      setCheckCoupounResult(err.message);
    });
  };

  const steps = [
    {
      header: t("Select Method"),
      content: (
        <>
          <div>
            <div className="mb-0">
              <h6 className="mb-3">
                {t("Select Payment Method")}
              </h6>
              <Row className="justify-content-center payment-methods">
                {
                  allowedMethods.filter((method) => method.allowed.includes(type)).map((method) => (
                    <Col key={method.gateway} xs={4} lg={2} className="my-2">
                      <button
                        type="button"
                        onClick={() => {
                          setGateway(method.gateway);
                          setSelectedPaymentMethod(method.gateway);
                        }}
                        onMouseEnter={() => { setHoveredPaymentMethod(method.gateway) }}
                        onMouseLeave={() => { setHoveredPaymentMethod() }}
                        className={`btn btn-${selectedPaymentMethod === method.gateway ? "success" : hoveredPaymentMethod === method.gateway ? "default" : "light"} waves-effect waves-light w-sm py-4`}
                        style={{
                          height: "100%",
                          maxHeight: "80px",
                          width: "100%",
                          maxWidth: "80px",
                        }}
                      >
                        <img
                          src={method.image}
                          style={{
                            objectFit: "cover",
                            maxWidth: "100%",
                            maxHeight: "100%",
                          }}
                        ></img>
                      </button>
                    </Col>
                  ))
                }
              </Row>
            </div>
            {/* {gateway && stageOnePaymentDetails()} */}
            
              <h6 className="mb-3 d-flex align-items-center justify-content-between">{t("Required Amount")} :{plan?.price} {t("USD")}</h6>
              <div className="mb-3">
                <h6 className="mb-3 d-flex align-items-center justify-content-between">{t("Discount Coupoun")}</h6>
                <div className="d-flex gap-2">
                  <input 
                    type="text"
                    value={discountCoupoun}
                    onChange={(e)=> setDiscountCoupoun(e.target.value)}
                    className="form-control form-control-md"
                    placeholder="Please Add Discount Coupoun If Exist"
                    />
                    <Button
                      type="button"
                      className="btn btn-primary"
                      disabled={(!(loadingCheckCoupoun || discountCoupoun))}
                      onClick={checkForDiscountCoupounFn}
                    >
                      {
                        loadingCheckCoupoun ? 
                          <div className="text-center">
                            <Spinner className="ms-2" color="primary" />
                          </div>
                        :
                          t("Check")
                      }
                    </Button>
                </div>
                <h6 className="mb-2">{checkCoupounResult}</h6>
                {priceAfterDiscount && <h6 className="mb-2">{t("Price After Discount")}: {priceAfterDiscount} {t("USD")}</h6>}
              </div>
              <div className="mb-3">
                {/* <Label className="form-label" htmlFor="receipt">
                  {t("Receipt")}
                </Label> */}
                <h6 className="mb-3">
                  {t("Receipt")}
                </h6>
                <AvForm>
                  <AvField
                    type="file"
                    name="receipt"
                    className="form-control form-control-md"
                    errorMessage={t("Receipt is required")}
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                    }}
                    validate={{
                      required: { value: true },
                      validate: validateFile(["jpg", "jpeg", "png"], 1000000, file, {
                        sizeValidationMessage: t("The file size is too large"),
                        extensionValidationMessage: t("The file extension is not allowed"),
                      })
                    }}
                  />
                  
                </AvForm>
              </div>
            
            <WireTransfer 
              t={t} 
              setIsFirstStepValid={setIsFirstStepValid}
              setBaseCurrency={setBaseCurrency} 
            />

            {/* { stageOnePaymentDetails()} */}
            <div className="text-center mt-4">
            <AvForm
              onValidSubmit={(e, v) => {
                setLoading(true);
                const formData = new FormData();
                formData.append("receipt", file);
                formData.append("planId", plan?._id);
                formData.append("gateway", "WIRE_TRANSFER");
                formData.append("platform", platform);
                formData.append("preference", preference);
                formData.append("tradingCapital", tradingCapital);
                formData.append("discountCoupoun", discountCoupoun);
                formData.append("amount", parseFloat(plan?.price));
                formData.append("note", "note");
                // formData.append("tradingAccountId", v.tradingAccountId);
                submitHandler(formData);
              }}
            >
              <Button
                type="submit"
                // type="button"
                className="create-challenge-plan__enroll"
                
                disabled={!((isFirstStepValid) && file)}
                // onClick={() => toggleTab(1)}
                // onClick={() => submitHandler({
                //   gateway,
                //   paymentPayload,
                //   // amount: parseFloat(amount),
                //   note: "note",
                //   tradingAccountId: v.tradingAccountId,
                // })}
              >
                {
                  loading ? 
                    <div className="text-center">
                      <Spinner className="ms-2" color="primary" />
                    </div>
                  :
                    t("Continue")
                }
              </Button>
            </AvForm>
            </div>
          </div>
        </>
      ),
    },
    // {
    //   header: "Enter Amount",
    //   content: (
    // <StageTwo
    //       t={t}
    //       setLoading={setLoading}
    //       loading={loading}
    //       toggleTab={toggleTab}
    //       type={type}
    //       gateway={gateway}
    //       handleSubmit={submitHandler}
    //       paymentPayload={paymentPayload}
    //       receipt={allowedMethods.find((method) => method.gateway === gateway)?.receipt}
    //       targetCurrency={targetCurrency}
    //       baseCurrency={baseCurrency}
    //     />
    //   ),
    // },
    {
      header: "Deposit status",
      content: (
        <>
          <div className="text-center  mb-4">
            <h1 className="fs-1 mb-5">
              {t("Yay!")} <i className="bx bx-wink-smile"></i>
            </h1>
            <p>{t("Pending Deposit Thank You")}</p>
            <p>
              <span className="positive">
                {
                  type === "fiatDeposit" ? result?.result?.amount?.$numberDecimal : result?.result?.amount
                }
                {
                  " "
                }
                {
                  type === "fiatDeposit" ? result?.result?.currency : result?.result?.currency
                }
              </span>
            </p>
            <span className="text-muted">
              {t("Your transaction ID is")}
              {result?.result?._id}
            </span>
          </div>
          <CardWrapper className="mb-4">
            <div className="d-flex align-items-center justify-content-around px-4">
              <div>
                <div className="text-muted">{t("Status")}</div>
                <div className="positive">{t(result?.result?.status)}</div>
              </div>
              {/* <div>
                <div className="text-muted">{t("GATEWAY")}</div>
                <div>{result.result?.gateway}</div>
              </div> */}
            </div>
          </CardWrapper>
        </>
        // <>
        //   {result.status ? (
        //     <>
        //       <div className="text-center  mb-4">
        //         <h1 className="fs-1 mb-5">
        //           {t("Yay!")} <i className="bx bx-wink-smile"></i>
        //         </h1>
        //         <p>{t("Pending Deposit Thank You")}</p>
        //         <p>
        //           <span className="positive">
        //             {
        //               type === "fiatDeposit" ? result?.result?.amount?.$numberDecimal : result?.result?.amount
        //             }
        //             {
        //               " "
        //             }
        //             {
        //               type === "fiatDeposit" ? result?.result?.currency : result?.result?.currency
        //             }
        //           </span>
        //         </p>
        //         <span className="text-muted">
        //           {t("Your transaction ID is")}
        //           {result?.result?._id}
        //         </span>
        //       </div>
        //       <CardWrapper className="mb-4">
        //         <div className="d-flex align-items-center justify-content-around px-4">
        //           <div>
        //             <div className="text-muted">{t("Status")}</div>
        //             <div className="positive">{t(result?.result?.status)}</div>
        //           </div>
        //           <div>
        //             <div className="text-muted">{t("GATEWAY")}</div>
        //             <div>{result.result?.gateway}</div>
        //           </div>
        //         </div>
        //       </CardWrapper>
        //     </>
        //   ) : (
        //     <>
        //       <div className="text-center  mb-4">
        //         <h1 className="fs-1 mb-5">
        //           {t("Oops!")} <i className="bx sad"></i>
        //         </h1>
        //         <p>{t("Your Deposit Request Not Successfully Created")}</p>
        //       </div>
        //       <CardWrapper className="mb-4">
        //         <div className="d-flex align-items-center justify-content-between px-5">
        //           <div>
        //             <div className="positive">{result.message}</div>
        //           </div>
        //         </div>
        //       </CardWrapper>
        //     </>
        //   )}
        //   <div className="text-center">
        //     <Button
        //       className="create-challenge-plan__enroll"
        //       onClick={toggleOpen}
        //     >
        //       {t("Continue")}
        //     </Button>
        //   </div>
        // </>
      ),
    },
  ];
  const {
    layoutMode,
  } = useSelector(state => ({
    layoutMode: state.Layout.layoutMode,
  }));
  return (
    <>
    {/* <div className="modal-steps">
        {steps.map((step, index) =>
          <div key={index} className={classnames("step", { active: activeStep === index })} onClick={() => {toggleTab(index)}}>
            <button type="button" className={"btn btn-light btn-rounded number step-button"} onClick={() => { }}>{index + 1}</button>
            <span className={(activeStep == index) ? "text fw-bold text-secondary" : "text"}>{step.header}</span>
            {steps.length - 1 !== index && <div className="vl">
              <div className="vl-1"></div>
              <div className="vl-2"></div>
              <div className="vl-3"></div>
            </div>}
          </div>
        )}
      </div>
      <div className="modal-body">
        {steps[activeStep]?.content}
      </div> */}
      <CustomModal
        steps={steps}
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        activeStep={activeStep}
        toggleTab={toggleTab}
      ></CustomModal>
    </>

  );
}
export default withTranslation()(Deposit); 
