import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Modal, ModalBody, ModalHeader
} from "reactstrap";
import Select from "react-select";
import { useState } from "react";
import { subscribeToCompetition } from "apis/competitions";
import {
 fetchCompetitions, showErrorNotification, showSuccessNotification 
} from "store/actions";

function SubscriptionModal({ open, onClose, t, comp, loadCompetitions }) {
    const dispatch = useDispatch();
    const { accounts } = useSelector(state => state.forex);
    const [tradingAccount, setTradingAccount] = useState(null);
    const [loading, setLoading] = useState(false);
    const subscribe = async () => {
        setLoading(true);
        const data = await subscribeToCompetition(tradingAccount, comp);
        if (data && data.isSuccess) {
            dispatch(showSuccessNotification("Subscribed to competition successfully"));
            dispatch(fetchCompetitions());
            onClose();
        } else {
            dispatch(showErrorNotification(data.message));
        }
        setLoading(false);
    };
    return (<>
        <Modal isOpen={open} toggle={onClose} centered={true}>
            <ModalHeader toggle={onClose} tag="h4">
                {t("Subscribe")}
            </ModalHeader>
            <ModalBody>
                <Select
                  name="tradingAccount"
                  options={accounts?.accounts?.filter(acc => acc?.accountTypeId?._id?.toString() === comp?.accountTypeId?.toString() && !comp?.isSubscribed?.logins.includes(acc.login))?.map((val) => {
                        return {
                            label: `${val.login} ${val.platform} ${val.Balance}`,
                            value: val.login
                        };
                    })}
                  onChange={(val) => {
                        setTradingAccount(val.value);
                    }}
                >
                </Select>
                <div className="d-flex align-items-center justify-content-center">
                    <Button className="mt-4" onClick={() => {
                        subscribe();
                    }} disabled={loading}>
                        {t("Select Account")}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    </>);
}

export default SubscriptionModal;