import React, { useEffect } from "react";
// eslint-disable-next-line object-curly-newline
import { Button, Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import MetaTags from "react-meta-tags";

// import { fetchWallets, toggleCurrentModal } from "src/store/actions";
//i18n
import { withTranslation } from "react-i18next";
import CardWrapper from "components/Common/CardWrapper";
import CustomTable from "components/Common/CustomTable";
import { getAssetImgSrc } from "helpers/assetImgSrc";
import { fetchWallets, toggleCurrentModal } from "store/actions";
import Loader from "components/Common/Loader";
import { JClickHandler } from "components/Journey/handlers";
import "./MyChallenges.scss";
import { Link } from "react-router-dom";

function MyChallenges(props) {
  return (
    <div className="page-content">
      <MetaTags>
        <title>{props.t("Create")}</title>
      </MetaTags>
      <Container>
        <div className="my-5">
          <div className="challengs-top-bar-container mb-5">
            <div className="challengs-top-bar-item challengs-top-bar-item--active">
              <span className="challengs-top-bar-number">1</span>
              <p className="challengs-top-bar-text">{props.t("Plan")}</p>
            </div>
            <div className="challengs-top-bar-item challengs-top-bar-item--bar">
              <span className="challengs-top-bar-number">2</span>
              <p className="challengs-top-bar-text">{props.t("Payment Methods")}</p>
            </div>
            <div className="challengs-top-bar-item">
              <span className="challengs-top-bar-number">3</span>
              <p className="challengs-top-bar-text">{props.t("Checkout")}</p>
            </div>
          </div>
          <div className="challenge-plan-cards-wrapper">
            <CardWrapper className="mb-5 glass-card shadow challenge-plan-card">
              <h2 className="challenge-plan-card-title">titleeee</h2>
              <p className="challenge-plan-card-description">descriptioooon</p>
              <Link to="/" className="challenge-plan-card-link">{props.t("Need More Info?")}</Link>
              <button type="button" className="btn btn-secondary challenge-plan-card-price">$100</button>
            </CardWrapper>
            <CardWrapper className="mb-5 glass-card shadow challenge-plan-card">
              <h2 className="challenge-plan-card-title">titleeee</h2>
              <p className="challenge-plan-card-description">descriptioooon</p>
              <Link to="/" className="challenge-plan-card-link">{props.t("Need More Info?")}</Link>
              <button type="button" className="btn btn-secondary challenge-plan-card-price">$100</button>
            </CardWrapper>
            <CardWrapper className="mb-5 glass-card shadow challenge-plan-card">
              <h2 className="challenge-plan-card-title">titleeee</h2>
              <p className="challenge-plan-card-description">descriptioooon</p>
              <Link to="/" className="challenge-plan-card-link">{props.t("Need More Info?")}</Link>
              <button type="button" className="btn btn-secondary challenge-plan-card-price challenge-plan-card-price--active">$100</button>
            </CardWrapper>
            
            
          </div>
        </div>
      </Container>
    </div>
  );
}
export default withTranslation()(MyChallenges);
