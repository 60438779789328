import React, { useEffect, useState } from "react";
// eslint-disable-next-line object-curly-newline
import { Button, Col, Container, Label, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import MetaTags from "react-meta-tags";

// import { fetchWallets, toggleCurrentModal } from "src/store/actions";
//i18n
import { withTranslation } from "react-i18next";
import CardWrapper from "components/Common/CardWrapper";
import CustomTable from "components/Common/CustomTable";
import { getAssetImgSrc } from "helpers/assetImgSrc";
import { fetchWallets, toggleCurrentModal } from "store/actions";
import Loader from "components/Common/Loader";
import { JClickHandler } from "components/Journey/handlers";
import "./CreateChallenge.scss";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import Deposit from "pages/Deposit/Deposit";

function ChoosePaymentMethod(props) {
  const {
    plan,
    planType,
    platform,
    tradingCapital,
    preference,
  } = props;
  return (
    <div className="page-content">
      <MetaTags>
        <title>{props.t("Create")}</title>
      </MetaTags>
      <Container>
        <div className="my-5 choose-payment-method">
          <Row>
            <Col lg="7">
              <Deposit/>
            </Col>
            <Col lg="5">
              <CardWrapper className="glass-card shadow choose-payment-method__card">
                <div className="choose-payment-method__card-title-wrapper">
                  <h2 className="choose-payment-method__card-title">{props.t("Total Amount to be Paid")}</h2>
                  <p className="choose-payment-method__card-total-price">${plan?.price}</p>
                </div>
                <div className="choose-payment-method__card-content-wrapper">
                  <div className="choose-payment-method__card-item-wrapper">
                    <label className="choose-payment-method__card-item-label">{props.t("Plan")}</label>
                    <p className="choose-payment-method__card-item-text">{plan?.title}</p>
                  </div>
                  <div className="choose-payment-method__card-item-wrapper">
                    <label className="choose-payment-method__card-item-label">{props.t("Platform")}</label>
                    <p className="choose-payment-method__card-item-text">{platform}</p>
                  </div>
                  <div className="choose-payment-method__card-item-wrapper">
                    <label className="choose-payment-method__card-item-label">{props.t("Phases")}</label>
                    <p className="choose-payment-method__card-item-text">{planType}</p>
                  </div>
                  <div className="choose-payment-method__card-item-wrapper">
                    <label className="choose-payment-method__card-item-label">{props.t("Account Type")}</label>
                    <p className="choose-payment-method__card-item-text">{preference}</p>
                  </div>
                  <div className="choose-payment-method__card-item-wrapper">
                    <label className="choose-payment-method__card-item-label">{props.t("Price")}</label>
                    <p className="choose-payment-method__card-item-text">${plan?.price}</p>
                  </div>
                </div>
              </CardWrapper>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
export default withTranslation()(ChoosePaymentMethod);
