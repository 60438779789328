import React, { useEffect, useState } from "react";
// eslint-disable-next-line object-curly-newline
import { Button, Col, Container, Row, Spinner } from "reactstrap";
import MetaTags from "react-meta-tags";
import "./AccountOverview.scss";
import { useParams } from "react-router-dom";
//i18n
import { withTranslation } from "react-i18next";
import CardWrapper from "components/Common/CardWrapper";
import demoAvatar from "assets/images/demo-avatar.jpg";
import { ReactComponent as AccountDetailsIcon } from "assets/img/icons/account-details.svg";
import { ReactComponent as CopyIcon } from "assets/img/icons/copy-icon.svg";
import { ReactComponent as TradingCycleDetails } from "assets/img/icons/trading-cycle-details-icon.svg";
import AccountOverviewInfo from "./AccountOverviewInfo/AccountOverviewInfo";
import AccountOverviewStats from "./AccountOverviewStats/AccountOverviewStats";
import AccountOverviewConsistencyHistory from "./AccountOverviewConsistencyHistory/AccountOverviewConsistencyHistory";
import AccountOverviewGrowth from "./AccountOverviewGrowth/AccountOverviewGrowth";
import AccountOverviewTradingObjective from "./AccountOverviewTradingObjective/AccountOverviewTradingObjective";
import { fetchChallenge, fetchChallengesGrouped } from "apis/challenges";
import AccountPrizeOverviewInfo from "pages/PrizesOverview/AccountPrizesOverview/AccountOverviewInfo/AccountPrizeOverviewInfo";
import AccountPrizeOverviewConsistencyHistory from "pages/PrizesOverview/AccountPrizesOverview/AccountOverviewConsistencyHistory/AccountPrizeOverviewConsistencyHistory";
import AccountPrizeOverviewTradingObjective from "pages/PrizesOverview/AccountPrizesOverview/AccountOverviewTradingObjective/AccountPrizeOverviewTradingObjective";
import TransferPrizeModal from "components/Modals/TransferPrize/TransferPrize";
import { toggleCurrentModal } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
function AccountOverview(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [challenge, setChallenge] = useState();
  const [prize, setPrize] = useState();
  const [isFirstLoading, setIsFirstLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPrizeShown, setIsPrizeShown] = useState(false);
  const { currentModal } = useSelector((state) => ({
    currentModal: state.Layout.currentModal,
    modalData: state.Layout.modalData,
  }));
  const [activeChallenge, setActiveChallenge] = useState();
  const [plan, setPlan] = useState([]);
  function changeChallenge(id) {
    // const changedChallenge = plan?.challenges?.find((challenge) => challenge?._id === id);
    // console.log("challenge", challenge);
    // setChallenge(changedChallenge);
    setIsPrizeShown(false);
    setActiveChallenge(id);
    setIsLoading(true);
    fetchChallenge(id).then((res) => {
      setChallenge(res?.result);
      setIsLoading(false);
      // setLoading(false);
      // setResult(res);
      // toggleTab(1);
    }).catch((err) => {
      setIsLoading(false);
      // setLoading(false);
      // setResult(err);
      // toggleTab(1);
    });
  }
  useEffect(()=>{
      setIsFirstLoading(true);
      fetchChallengesGrouped({ groupId: id }).then((res) => {
          setIsFirstLoading(false);
          setPlan(res?.result?.plans?.[0]);
          setChallenge(res?.result?.challenge);
          setPrize(res?.result?.prize);
          setActiveChallenge(res?.result?.challenge?._id);
          res?.result?.prize && setIsPrizeShown(true);
          // setChallenge(res?.result);
          // setLoading(false);
          // setResult(res);
          // toggleTab(1);
      }).catch((err) => {
          setIsFirstLoading(false);
          // setLoading(false);
          // setResult(err);
          // toggleTab(1);
      });
  }, []);
  return (
    <div className="page-content">
      <MetaTags>
        <title>{props.t("Account Overview")}</title>
      </MetaTags>
      <Container>
      {
        isFirstLoading ? 
          <div className="d-flex justify-content-center align-items-center mt-5">
            <Spinner type="border" className="ms-2" color="secondary" />
          </div>
        :
          <Row>
            <h1 className="mt-5">{plan?.plan?.title}</h1>
            <Col lg="12">
            <div className="navbar-header bg-transparent">
              <div className="container-fluid">
                <ul className="nav-tabs-custom nav-start nav nav-tabs page-menues">
                  {
                    plan?.challenges?.map((challenge, index) => (
                      <li className={`nav-item item-${index}`} key={challenge?.id}>
                        <button 
                          className={`nav-link tab-button ${activeChallenge === challenge?._id && !isPrizeShown ? "active fw-bold" : "unselected"}`}
                          type="button" 
                          onClick={()=> changeChallenge(challenge?._id)}
                          >
                            { challenge?.title }
                        </button>
                      </li>
                    )) 
                  }
                  {
                    prize &&
                      <li className={"nav-item item-10"}>
                        <button className={`nav-link tab-button ${isPrizeShown ? "active fw-bold" : "unselected"}`} 
                          type="button" 
                          onClick={()=> setIsPrizeShown(true)}
                        >
                          Prize
                        </button>
                      </li>
                  }
                </ul>
              </div>
            </div>
            </Col>
            {
              isLoading && <div className="d-flex justify-content-center align-items-center"><Spinner type="border" className="ms-2" color="secondary" /></div>
            }
            {
              (challenge && !isPrizeShown) &&
              <>
                <Col lg="12" className="my-5 mt-2">
                  <AccountOverviewInfo challenge={challenge}/>
                </Col>
                <Row>
                  <Col lg="9" >
                    <Row>
                      <Col lg="12" className="mb-5">
                        <AccountOverviewStats challenge={challenge}/>
                      </Col>
                      <Col lg="12" className="mb-5">
                        <AccountOverviewTradingObjective challenge={challenge}/>
                      </Col>
                      {/* <Col lg="12"  className="mb-5">
                        <AccountOverviewConsistencyHistory challenge={challenge}/>
                      </Col> */}
                    </Row>
                  </Col>
                  <Col lg="3">
                    <AccountOverviewGrowth challenge={challenge}/>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" className="mb-5">
                    <AccountOverviewConsistencyHistory challenge={challenge}/>
                  </Col>
                </Row>
              </>
            }
            {
              (prize && isPrizeShown ) &&
              <>
                {
                  (Number(prize?.balance) > prize?.initialBalance) > 0 &&
                    <div>
                      <button className={"btn d-flex mx-2 btn-primary"} type="button" onClick={()=> dispatch(toggleCurrentModal("TransferPrizeModal"))}>
                        Transfer Prize Money To Wallet
                      </button>
                    </div>
                }
                {
                  (currentModal === "TransferPrizeModal" && (Number(prize?.balance) > prize?.initialBalance)) && 
                  <TransferPrizeModal prizeObject={prize?.prizeObject} accountId={prize?._id}/>
                }
                <Col lg="12" className="my-5 mt-2">
                  <AccountPrizeOverviewInfo challenge={prize}/>
                </Col>
                <Row>
                  <Col lg="12" >
                    <Row>
                      {/* <Col lg="12"  className="mb-5">
                        <AccountOverviewStats challenge={challenge}/>
                      </Col>
                      <Col lg="12"  className="mb-5">
                        <AccountOverviewTradingObjective challenge={challenge}/>
                      </Col> */}

                      <Col lg="12"  className="mb-5">
                        <AccountPrizeOverviewTradingObjective challenge={prize}/>
                      </Col>
                      <Col lg="12"  className="mb-5">
                        <AccountPrizeOverviewConsistencyHistory challenge={prize}/>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col lg="3">
                    <AccountOverviewGrowth challenge={challenge}/>
                  </Col> */}
                </Row>
              </>
            }
          </Row>
      }
      </Container>
    </div>
  );
}
export default withTranslation()(AccountOverview);
