import {
  FETCH_COMPETITIONS_START,
  FETCH_COMPETITIONS_SUCCESS,
  FETCH_COMPETITIONS_ERROR,
  SUBSCRIBE_COMPETITION_START
} from "./actionTypes";

export const fetchCompetitions = (data) => {
  return {
    type: FETCH_COMPETITIONS_START,
    payload: data
  };
};

export const fetchCompetitionsSuccess = (data) => {
  return {
    type: FETCH_COMPETITIONS_SUCCESS,
    payload: data
  };
};

export const fetchCompetitionsError = (data) => {
  return {
    type: FETCH_COMPETITIONS_ERROR,
    payload: data
  };
};

export const subscribeToCompetition = (data) => {
  return {
    type: SUBSCRIBE_COMPETITION_START,
    payload: data
  };
};