import { getCompetitions } from "apis/competitions";
import CardWrapper from "components/Common/CardWrapper";
import { useEffect, useState } from "react";
import {
    Button,
    CardHeader, Container, Table
} from "reactstrap";
import {
 fetchCompetitions, getAccountsStart, showErrorNotification 
} from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import {
    Th,
    Thead,
    Tr
} from "react-super-responsive-table";
import { withTranslation } from "react-i18next";
import SubscriptionModal from "./SubscriptionModal";
import { useHistory } from "react-router-dom";
import UnSubscriptionModal from "./UnSubscriptionModal";
import Loader from "components/Common/Loader";

function CompetitionDetails(props) {
    const [competitions, setCompetitions] = useState([]);
    const [selectedComp, setSelectedComp] = useState(null);
    const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
    const [openUnSubscriptionModal, setOpenUnSubscriptionModal] = useState(false);
    const { getCompetitions } = useSelector(state => state.competitionReducer);
    const history = useHistory();
    const dispatch = useDispatch();
    

    useEffect(() => {
        dispatch(fetchCompetitions());
        dispatch(getAccountsStart({ type: "LIVE" }));
    }, []);

    const columns = [
        {
            text: props.t("Name"),
            formatter: (val) => val.name
        },
        {
            dataField: "cpText",
            text: props.t("Description"),
        },
        {
            dataField: "totalSubscribers",
            text: props.t("Total Subscribers"),
        },
        {
            dataField: "totalWinners",
            text: props.t("Total Winners"),
        },
        {
            dataField: "Accounts",
            text: props.t("Accounts"),
            formatter: (val) => {
                return val?.isSubscribed?.logins?.toString();
            }
        },
        {
            text: "Leaderboard",
            formatter: (val) => {
                return <Button onClick={() => {
                    history.push("/competition/leaderboard", val);
                }}>
                    {props.t("Show")}
                </Button>;
            }
        },
        {
            text: props.t("Join"),
            formatter: (val) => {
                if (val.clientType === "LIVE" && val.status !== "COMPLETED" && !val.isMaxAccSubscribed)
                    return <Button onClick={() => {
                        setOpenSubscriptionModal(true);
                        setSelectedComp(val);
                    }}>{props.t("Subscribe")}</Button>;
            }
        },
        {
            text: props.t("UnJoin"),
            formatter: (val) => {
                if (val.clientType === "LIVE" && val.status !== "COMPLETED" &&
                 val.isSubscribed)
                    return <Button onClick={() => {
                        setOpenUnSubscriptionModal(true);
                        setSelectedComp(val);
                    }}>{props.t("Unsubscribe")}</Button>;
            }
        }
    ];
    return (<>
        <div className="page-content">
            <Container className="mt-5">
                <CardWrapper className="mt-5">
                    <CardHeader title="Competitions"></CardHeader>
                    {getCompetitions.loading ? <Loader></Loader> :
                     getCompetitions.docs && getCompetitions.docs.length > 0 ?
                        <Table borderless responsive className="text-center mb-0" >
                            <Thead className="text-center table-light">
                                <Tr>
                                    {columns.map((column, index) => (
                                        <Th data-priority={index} key={index} className="color-primary">
                                            {column.text}
                                        </Th>
                                    ))}
                                </Tr>
                            </Thead>
                             <tbody>
                                {(getCompetitions.docs?.map((item, rank) => {
                                    return (
                                        <Tr key={rank}>
                                            {columns.map((column, index) => (
                                                <td key={index} data-priority={index}>
                                                    {column.formatter ? column.formatter({
                                                        ...item,
                                                        rank
                                                    }) : item[column.dataField]}
                                                </td>
                                            ))}
                                        </Tr>
                                    );
                                })
                                )}
                            </tbody>
                        </Table> : <h4 className="text-center">{props.t("No available competitions available")}</h4>}
                </CardWrapper>
            </Container>
            <SubscriptionModal
              open={openSubscriptionModal}
              onClose={() => {
                    setOpenSubscriptionModal(false);
                }}
              t={props.t}
              comp={selectedComp}
            ></SubscriptionModal>
            <UnSubscriptionModal
              open={openUnSubscriptionModal}
              onClose={() => {
                    setOpenUnSubscriptionModal(false);
                }}
              t={props.t}
              comp={selectedComp}
            ></UnSubscriptionModal>
        </div>
    </>);
}

export default withTranslation()(CompetitionDetails);