// export const GENERATE_QR_CODE_START = "GENERATE_QR_CODE_START";
// export const GENERATE_QR_CODE_SUCCCESS = "GENERATE_QR_CODE_SUCCCESS";
// export const GENERATE_QR_CODE_ERROR = "GENERATE_QR_CODE_ERROR";
export const REQUEST_IB_START = "REQUEST_IB_START";
export const REQUEST_IB_SUCCESS = "REQUEST_IB_SUCCESS";
export const REQUEST_IB_FAIL = "REQUEST_IB_FAIL";
export const GET_REQUEST_IB_START = "GET_REQUEST_IB_START";
export const GET_REQUEST_IB_SUCCESS = "GET_REQUEST_IB_SUCCESS";
export const GET_REQUEST_IB_FAIL = "GET_REQUEST_IB_FAIL";
// export const VERIFY_TWO_FACTOR_CODE_SUCCESS = "VERIFY_TWO_FACTOR_CODE_SUCCESS";
// export const VERIFY_TWO_FACTOR_CODE_FAIL = "VERIFY_TWO_FACTOR_CODE_FAIL";