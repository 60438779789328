import { AvField, AvForm } from "availity-reactstrap-validation";
import calculateFee from "helpers/calculateFee";
import { validateFile } from "helpers/validations/file";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  InputGroup,
  InputGroupText,
  Label
} from "reactstrap";
import FiatFormDetails from "./Fiat/FiatFormDetails";
import Mt5Details from "./MT5/Mt5Details";
import PlanFormDetails from "./plan/PlanFormDetails";
import { getFiniticPayFeesConfig } from "apis/deposit";


export default function StageTwo(props) {
  const {
    t,
    toggleTab,
    type,
    gateway,
    handleSubmit,
    handleSubmitPlan,
    receipt = false,
    loading,
    paymentPayload,
    setLoading,
    plan,
    platform, 
    tradingCapital, 
    preference,
  } = props;

  const [file, setFile] = useState({});
  const [amount, setAmount] = useState();
  const [amountValidation, setAmountValidation] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState(undefined);
  const tranFeeGroupDetails = useSelector((state) => state.Profile?.clientData?.transactionFeeId);
  const [transactionFee, setTransactionFee] = useState(0);
  const [discountCoupoun, setDiscountCoupoun] = useState("");
  const [fees, setFees] = useState({});
  const [feesLoading, setFeesLoading] = useState(false);

  useEffect(() => {
    if (gateway === "FINITIC_PAY") {
      setFeesLoading(true);
      getFiniticPayFeesConfig()
        .then((resp) => resp.result)
        .then((res) => setFees(res))
        .finally(() => setFeesLoading(false));
    }
  }, [gateway]);

  const amountValidationHandler = () => {
    amount < 15 ? setAmountValidation(true) : setAmountValidation(false);
  };

  useEffect(() => {
    // setTransactionFee(tranFeeGroupDetails ? calculateFee(tranFeeGroupDetails, amount) : 0);
    if (amount > 0 && fees && fees.value) {
      const { value, isPercentage, minValue } = fees;
      if (isPercentage) {
        console.log(value, amount);
        const fee = parseFloat((amount * value / 100)?.toFixed(2));
        console.log(fee, minValue);
        const rawFee = fee < parseFloat(minValue) ? parseFloat(minValue) : fee;
        setTransactionFee(rawFee);
      } else {
        setTransactionFee(value);
      }
    } else {
      setTransactionFee(0);
    }
    if (type !== "newDeposit") {
      amountValidationHandler();
    }
  }, [amount, fees, gateway]);

  useEffect(() => {
    if (plan) {
      setAmount(plan?.price);
    }
  }, [plan]);
  useEffect(() => {
    if (type === "newDeposit") {
      setAmountValidation(false);
    }
  }, [type]);

  const renderFormType = () => {
    switch (type) {
      case "fiatDeposit":
        return <FiatFormDetails t={t}
          selectedWallet={selectedWallet}
          amount={amount}
          setAmount={setAmount}
          setAmountValidation={setAmountValidation}
          setSelectedWallet={setSelectedWallet}
        />;
      case "newDeposit":
        return <PlanFormDetails t={t}
          selectedWallet={selectedWallet}
          plan={plan}
          platform={platform}
          preference={preference}
          tradingCapital={tradingCapital}
          amount={amount}
          setDiscountCoupoun={setDiscountCoupoun}
          discountCoupoun={discountCoupoun}
          transactionFee={transactionFee}
          gateway={gateway}
          setAmount={setAmount}
          setAmountValidation={setAmountValidation}
          setSelectedWallet={setSelectedWallet}
        />;
      case "mt5Deposit":
        return <Mt5Details
          t={t}
          amount={amount}
          setAmount={setAmount}
          amountValidation={amountValidation}
        />;
      default:
        return <></>;
    }
  };


  return (
    <AvForm
      onValidSubmit={(e, v) => {
        setLoading(true);
        const formData = new FormData();
        const finalAmount = gateway === "WIRE_TRANSFER" ? amount : (amount);
        if (type === "mt5Deposit") {
          if (receipt) {
            formData.append("gateway", gateway);
            formData.append("amount", parseFloat(amount));
            formData.append("note", "note");
            formData.append("tradingAccountId", v.tradingAccountId);
            formData.append("receipt", file);
            formData.append("paymentPayload", paymentPayload);
            handleSubmit(formData);
          } else {
            if (gateway === "CRYPTO"){
              handleSubmit({
                gateway,
                paymentPayload,
                amount: parseFloat(amount),
                note: "note",
                tradingAccountId: v.tradingAccountId,
              });
            }
          }
        } else if (type === "fiatDeposit") {
          if (receipt) {
            formData.append("gateway", gateway);
            formData.append("amount", parseFloat(amount));
            formData.append("note", "note");
            formData.append("walletId", selectedWallet.value);
            formData.append("receipt", file);
            formData.append("paymentPayload", paymentPayload);
            handleSubmit(formData);
          } else {
            handleSubmit({
              gateway,
              paymentPayload,
              amount: parseFloat(amount),
              note: "note",
              walletId: selectedWallet.value,
            });
          }
      } else if (type === "newDeposit") {
        if (receipt) {
          formData.append("walletId", selectedWallet.value);
          formData.append("receipt", file);
          formData.append("planId", plan?._id);
          formData.append("gateway", gateway);
          formData.append("platform", platform);
          // formData.append("paymentPayload", paymentPayload);
          formData.append("preference", preference);
          formData.append("tradingCapital", tradingCapital);
          formData.append("discountCoupoun", discountCoupoun);
          formData.append("amount", parseFloat(finalAmount));
          formData.append("note", "note");
          handleSubmitPlan(formData, selectedWallet.value);
        } else {
          handleSubmitPlan({
            gateway,
            amount: parseFloat(finalAmount),
            note: "note",
            walletId: selectedWallet.value,
            // paymentPayload,
            planId: plan?._id,
            platform: platform,
            preference: preference,
            tradingCapital: tradingCapital,
            discountCoupoun: discountCoupoun,
          });
        }
      }
    }}
    >
      {
        gateway === "WESTERN_UNION" ? 
        <>
        <div className="my-3">
          <h2 className="mt-5" style={{
 textAlign:"center",
}}> Please contact us via Live Chat </h2>
          <p className="my-5">Follow the instruction below 1, please contact us via direct registeration to obtain transfer information 2, After converting the currency , take a photo of the currency and attach it to the advanced search to convert 3, when depositing through the Western Union portal, the deposited capital is withdrawn exclusievely Western Union , but it can be withdrawen via any withdrawel method available on the customer portal   </p>
        </div>
        </>
        
        : ""
      }

{
        gateway === "حواله بنكيه" ? 
        <>
        <div className="my-3">
          <h2 className="mt-5" style={{
 textAlign:"center",
}}> Please contact us via Live Chat </h2>
<p className="my-5">Follow the istructions below 1. Please contact us via live chat to get the transfer information 2.After  transfering the money , take a photo the transfer receipt and attach it in the Proof of Transfer field</p>
        </div>
        </>
        
        : ""
      }

{
        gateway === "PAYEER" ? 
        <>
        <div className="my-3">
          <h2 className="mt-5" style={{
 textAlign:"center",
}}> Send Money to : P1059861408</h2>
<p className="my-5">Follow the instructions below 1. Go to the Payeer App 2. Send Money to our wallet number above 3. Click Confirm Transfer , take a screenshot and upload it to the Confirm Traansfer field  </p>
        </div>
        </>
        
        : ""
      }
      <h6 className="mb-3">{t("Enter Amount")}</h6>
      <div className="d-flex justify-content-between mb-2">
        {renderFormType()}
      </div>
      {/* <div className="mb-3">
        <Label className="form-label mb-2">{t("Transaction Fee")}</Label>
        <InputGroup className="">
          <InputGroupText className=" w-100">
            {parseFloat(transactionFee ?? 0)}{"   "}{selectedWallet?.asset}
          </InputGroupText>
        </InputGroup>
      </div> */}
      {(gateway === "حواله بنكيه" || gateway === "WESTERN_UNION" || gateway === "PAYEER" || gateway === "WIRE_TRANSFER") && (
        <div className="mb-3">
          <Label className="form-label" htmlFor="receipt">
            {t("Receipt")}
          </Label>
          <AvField
            type="file"
            name="receipt"
            className="form-control form-control-md"
            errorMessage={t("Receipt is required")}
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
            validate={{
              required: { value: true },
              validate: validateFile(["jpg", "jpeg", "png"], 1000000, file, {
                sizeValidationMessage: t("The file size is too large"),
                extensionValidationMessage: t("The file extension is not allowed"),
              })
            }}
          />
        </div>
        
      )}
  
      <div className="my-4 text-center">
        <Button
          className="btn btn-primary m-2 btn-sm w-lg"
          onClick={() => toggleTab(0)}
        >
          {t("Back")}
        </Button>
        <Button
          className="btn btn-success m-2 btn-sm w-lg"
          type="submit"
          disabled={amountValidation || loading}
        >
          {t("Continue")}
        </Button>
      </div>

    </AvForm>
  );
}
