import { combineReducers } from "redux";

import generalReducers from "./general/reducers";
import walletReducer from "./wallets/reducer";
import planReducer from "./plans/reducer";
import { LOGOUT_USER } from "./general/auth/login/actionTypes";
import accounts from "./accounts/reducer";

const appReducers = combineReducers({
  ...generalReducers,
  shared: {}, // shared business logic between crypto and forex
  walletReducer,
  planReducer,
  accounts,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    localStorage.removeItem("persist:Layout");
    return appReducers({}, action);
  }
  return appReducers(state, action);
};


export default rootReducer;