import React, { useEffect, useState } from "react";
// eslint-disable-next-line object-curly-newline
import "./AccountOverviewConsistencyHistory.scss";
//i18n
import { withTranslation } from "react-i18next";
import CardWrapper from "components/Common/CardWrapper";
// import demoAvatar from "assets/images/demo-avatar.jpg";
// import { ReactComponent as StatsIcon } from "assets/img/icons/stats-icon.svg";
// import { ReactComponent as DollarIcon } from "assets/img/icons/dollar-icon.svg";
import { ReactComponent as ConsistencyHistory } from "assets/img/icons/consistency-history.svg";
import moment from "moment";
import { 
  Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, Table 
} from "reactstrap";
import { Tab, Tabs } from "react-bootstrap";

function OpenPositionsTable({items}) {
  return (
    <div className="border rounded-3 mt-4">
      <Table borderless responsive hover className="cursor-pointer text-center mb-Open Position">
        <thead>
          <tr>
            <th>Symbol</th>
            <th>Position</th>
            <th>Deal Type</th>
            <th>Profit</th>
            <th>Price Current</th>
            <th>Price SL</th>
            <th>Price TP</th>
            <th>Price Open</th>
            <th>Volume</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody className="border-top">
          {
            items && items?.map((item, index) => (
              <tr className="border-top" key={index}>
                <td className="centerV">{item?.symbol}</td>
                <td className="centerV">{item?.position}</td>
                <td className="centerV">
                  {
                    item?.action === 1 ? <span className="text-success">Buy</span> :
                      item?.action === 0 ? <span className="text-danger">Sell</span> :
                        "---"
                  }
                </td>
                <td className="centerV">{item?.profit}</td>
                <td className="centerV">{item?.priceCurrent}</td>
                <td className="centerV">{item?.priceSL}</td>
                <td className="centerV">{item?.priceTP}</td>
                <td className="centerV">{item?.priceOpen}</td>
                <td className="centerV">{item?.volume}</td>
                <td className="centerV">{moment(item?.time * 1000).format("dddd, MMMM Do YYYY, h:mm:ss a")}</td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </div>
  );
}

function ClosePositionsTable({items}) {
  return (
    <div className="border rounded-3 mt-4">
      <Table borderless responsive hover className="cursor-pointer text-center mb-Open Position">
        <thead>
          <tr>
            <th>Symbol</th>
            <th>Position</th>
            <th>Deal Type</th>
            <th>Profit</th>
            <th>Deal</th>
            <th>Price</th>
            <th>Price SL</th>
            <th>Price TP</th>
            <th>Volume</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody className="border-top">
          {
            items && items?.map((item, index) => (
              <tr className="border-top" key={index}>
                <td className="centerV">{item?.symbol}</td>
                <td className="centerV">{item?.positionID}</td>
                <td className="centerV">
                  {
                    item?.action === 1 ? "Buy" :
                    item?.action === 0 ? "Sell" :
                    "---"
                  }
                </td>
                <td className="centerV">{item?.profit}</td>
                <td className="centerV">{item?.deal}</td>
                <td className="centerV">{item?.price}</td>
                <td className="centerV">{item?.priceSL}</td>
                <td className="centerV">{item?.priceTP}</td>
                <td className="centerV">{item?.volume}</td>
                <td className="centerV">{moment(item?.time * 1000).format("dddd, MMMM Do YYYY, h:mm:ss a")}</td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </div>
  );
}

function AccountPrizeOverviewConsistencyHistory(props) {
  const {challenge} = props;
  const [key, setKey] = useState("0");
  return (
    <CardWrapper className="mb-5 glass-card shadow account-stats__wrapper">
      <h2 className="account-details__card-title">
        <ConsistencyHistory/>
        {props.t("History")}
      </h2>
      <div>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="0" title="Open Position">
            <OpenPositionsTable items={challenge?.openPositions}/>
          </Tab>
          <Tab eventKey="1" title="Closed Position">
            <ClosePositionsTable items={challenge?.closePositions}/>
          </Tab>
        </Tabs>
      </div>

    </CardWrapper>
  );
}
export default withTranslation()(AccountPrizeOverviewConsistencyHistory);
