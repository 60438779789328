import CustomSelect from "components/Common/CustomSelect";
import Loader from "components/Common/Loader";
import { getAssetImgSrc } from "helpers/assetImgSrc";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWallets } from "store/actions";
import {
  Button,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Spinner
} from "reactstrap";
import { checkForDiscountCoupoun } from "apis/plans";

export default function PlanFormDetails(props) {
  const {
    t,
    selectedWallet,
    amount,
    setAmount,
    setSelectedWallet,
    gateway,
    transactionFee,
    discountCoupoun,
    setDiscountCoupoun,
    plan,
  } = props;
  const dispatch = useDispatch();
  const [checkCoupounResult, setCheckCoupounResult] = useState("");
  const [checkCoupounResultStatus, setCheckCoupounResultStatus] = useState("");

  // const [discountCoupoun, setDiscountCoupoun] = useState("");
  const [priceAfterDiscount, setPriceAfterDiscount] = useState("");
  const [loadingCheckCoupoun, setLoadingCheckCoupoun] = useState(false);
  const { wallets, loading } = useSelector((state) => state?.walletReducer);

  const checkForDiscountCoupounFn = () => {
    const discountCouponData = {
      planId: plan?.id,
      discountCoupoun
    };
    setLoadingCheckCoupoun(true);
    checkForDiscountCoupoun(discountCouponData).then((res) => {
      setLoadingCheckCoupoun(false);
      setCheckCoupounResultStatus(res?.isSuccess);
      setCheckCoupounResult(res.message);
      if (res?.result?.newPlanPrice) {
        setPriceAfterDiscount(res?.result?.newPlanPrice);
        setAmount(res?.result?.newPlanPrice);
      }
    }).catch((err) => {
      setLoadingCheckCoupoun(false);
      setCheckCoupounResult(err.message);
    });
  };

  // useEffect(() => {
  //   if (amount < minAmount) {
  //     setAmountError(true);
  //     setAmountValidation(true);
  //   } else {
  //     setAmountError(false);
  //     setAmountValidation(false);
  //   }
  // }, [amount, minAmount, selectedWallet]);

  // useEffect(() => {
  //   const sWallet = wallets?.find((wallet) => wallet._id === selectedWallet?.value);
  //   setMinAmount(parseInt(sWallet?.assetId?.minAmount?.deposit) || 15);
  // }, [selectedWallet]);

  useEffect(() => {
    if (wallets?.length > 0) {
      setSelectedWallet({
        label: wallets[0].asset,
        value: wallets[0]._id,
        image: getAssetImgSrc(wallets[0].assetId)
      });
      // setMinAmount(parseInt(wallets[0].assetId?.minAmount?.deposit) || 15);
    }
  }, [wallets]);

  const loadWallets = () => {
    dispatch(fetchWallets());
  };

  useEffect(() => {
    if (!wallets || wallets?.length === 0) {
      loadWallets({
        isCrypto: false,
        isInventory: false,
      });
    }
  }, []);

  const fiatWallets = wallets?.map((wallet) => {
    return {
      label: wallet.asset,
      value: wallet._id,
      image: getAssetImgSrc(wallet.assetId)
    };
  });

  return (
    <div className="w-100">
      {loading ? <Loader /> : (
        <>
          <h6 className="mb-3 d-flex align-items-center justify-content-between">{t("Required Amount")} :{plan?.price} {t("USD")}</h6>
            <div className="mb-3">
              <h6 className="mb-3 d-flex align-items-center justify-content-between">{t("Discount Coupoun")}</h6>
              <div className="d-flex gap-2">
                <input 
                  type="text"
                  value={discountCoupoun}
                  onChange={(e)=> setDiscountCoupoun(e.target.value)}
                  className="form-control form-control-md"
                  placeholder="Please Add Discount Coupoun If Exist"
                  />
                  <Button
                    type="button"
                    className="btn btn-primary"
                    disabled={(!(loadingCheckCoupoun || discountCoupoun))}
                    onClick={checkForDiscountCoupounFn}
                  >
                    {
                      loadingCheckCoupoun ? 
                        <div className="text-center">
                          <Spinner className="ms-2" color="primary" />
                        </div>
                      :
                        t("Check")
                    }
                  </Button>
              </div>
              <h6 className={`mb-3 mt-2 d-flex justify-content-center m-auto ${checkCoupounResultStatus ? "text-success" : "text-danger"}`}>{checkCoupounResult}</h6>
              {priceAfterDiscount && <h6 className="mb-3 d-flex align-items-center gap-2">
                {t("Price After Discount")}: 
                <span className="fs-4">{priceAfterDiscount} {t("USD")}</span>
                </h6>}
            </div>
          {t("Transaction requirements")}
          <i className="fas fa-info-circle ms-2"></i>
          <div className="mt-3">
            <InputGroup>
              <Input
                required
                // onChange={(e) => {
                //   setAmount(e.target.value);
                // }}
                className="form-control"
                type="number"
                min="0"
                value={amount}
                placeholder={`Enter ${0}-128341`}
                disabled
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
              <InputGroupText className="custom-input-group-text p-0 border-0">
                <div style={{
                  width: "127px",
                  height: "100%"
                }}
                >
                  <CustomSelect
                    placeholder="Select"
                    name="wallets"
                    isRequired
                    options={fiatWallets}
                    defaultValue={fiatWallets[0]}
                    onChange={(e) => {
                      setSelectedWallet(e);
                    }}
                  >
                  </CustomSelect>
                </div>
              </InputGroupText>
            </InputGroup>
          </div>
          {/* {amountError && (
            <p className="small text-danger "> {
              !amount ? t("Amount is required") : t("Amount must be greater than " + minAmount)
            } </p>
          )} */}
          {
            gateway !== "WIRE_TRANSFER" &&
              <div className="mb-3">
                <Label className="form-label mb-2">{t("Transaction Fee")}</Label>
                <InputGroup className="">
                  <InputGroupText className=" w-100">
                    {parseFloat((transactionFee).toFixed(4) ?? 0)}{"   "}{selectedWallet?.asset}
                  </InputGroupText>
                </InputGroup>
              </div>
          }
          <div className="text-center fw-bolder mt-4 received-amount">
            <span className="fs-5">{selectedWallet?.label}</span>
            <span className="fs-1 text-danger">
              {
                gateway === "WIRE_TRANSFER" 
                ? Number(amount) : Number(amount) + Number(transactionFee) 
              }
            </span>
          </div>
        </>
      )}
    </div>
  );
}
