import config from "config";
import usdt from "assets/img/payment-method/USDT.png";

export const allowedMethods = [
  {
    gateway: "WIRE_TRANSFER",
    name: "Wire Transfer",
    image: "/img/payment-method/wire-transfer.png",
    allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit", "newDeposit"],
    receipt: true,
  },
  // {
  //   gateway: "OLX_FOREX",
  //   name: "Olx Forex",
  //   image: "/img/payment-method/olxforex.jpeg",
  //   allowed: ["fiatDeposit"],
  //   receipt: false,
  // },
  // {
  //   gateway: "CRYPTO",
  //   name: "Crypto",
  //   image: "/img/payment-method/crypto.png",
  //   allowed: config.companyCryptoDeposit ? ["mt5Deposit", "fiatDeposit", "cryptoDeposit", "newDeposit"] : [],
  //   receipt: false,
  // }, 
  // {
  //   gateway: "PRAXIS_B2Broker",
  //   name: "B2Broker - Finitic",
  //   image: "/img/payment-method/55.png",
  //   allowed:  ["mt5Deposit", "fiatDeposit", "cryptoDeposit", "newDeposit"],
  //   receipt: false,
  // },
  // {
  //   gateway: "Visa & Mastercard",
  //   name: "Visa & Mastercard",
  //   image: "/img/payment-method/Visa.jpg",
  //   allowed: config.companyCryptoDeposit ? ["mt5Deposit", "fiatDeposit", "cryptoDeposit"] : [],
  //   receipt: false,
  // }, 
  // {
  //   gateway: "صناديق-الاعتماد",
  //   name: "صناديق الاعتماد",
  //   image: "/img/payment-method/a3tmad.jpg",
  //   allowed:  ["mt5Deposit", "fiatDeposit", "cryptoDeposit", "newDeposit"],
  //   receipt: true,
  // },
  /* {
    gateway: "PRAXIS_PayMaxis",
    name: "PayMaxisAPM - Finitic",
    image: "/img/payment-method/paymax.webp",
    allowed:  ["mt5Deposit", "fiatDeposit", "cryptoDeposit", "newDeposit"],
    receipt: false,
  },
  {
    gateway: "PRAXIS_Brightcart",
    name: "Brightcart - Finitic",
    image: "/img/payment-method/44.png",
    allowed:  ["mt5Deposit", "fiatDeposit", "cryptoDeposit", "newDeposit"],
    receipt: false,
  },
  {
    gateway: "PRAXIS_Voucherry",
    name: "VoucherryAPM - Finitic",
    image: "/img/payment-method/66.png",
    allowed:  ["mt5Deposit", "fiatDeposit", "cryptoDeposit", "newDeposit"],
    receipt: false,
  },
  {
    gateway: "PRAXIS_AsiaBanks",
    name: "AsiaBanks - Finitic",
    image: "img/payment-method/77.png",
    allowed:  ["mt5Deposit", "fiatDeposit", "cryptoDeposit", "newDeposit"],
    receipt: false,
  }, */
  // {
  //   gateway: "PRAXIS_Coinspaid",
  //   name: "Coinspaid - Alphapo Crypto",
  //   image: "/img/payment-method/PRAXIS_Coinspaid.webp",
  //   allowed:  ["mt5Deposit", "fiatDeposit", "cryptoDeposit", "newDeposit"],
  //   receipt: false,
  // },
  // {
  //   gateway: "PRAXIS_PayMaxisAPM",
  //   name: "PayMaxisAPM - Crypto - Finitic",
  //   image: "/img/payment-method/PRAXIS_PayMaxisAPM.jpeg",
  //   allowed:  ["mt5Deposit", "fiatDeposit", "cryptoDeposit", "newDeposit"],
  //   receipt: false,
  // },
  // {
  //   gateway: "PRAXIS_PayMaxis_GATEWAY",
  //   name: "PayMaxis - Finitic",
  //   image: "/img/payment-method/PayMaxisAPM.jpeg",
  //   allowed:  ["mt5Deposit", "fiatDeposit", "cryptoDeposit", "newDeposit"],
  //   receipt: false,
  // },
  // {
  //   gateway: "PRAXIS_B2Broker",
  //   name: "B2Broker - Finitic",
  //   image: "/img/payment-method/B2Broker.jpeg",
  //   allowed:  ["mt5Deposit", "fiatDeposit", "cryptoDeposit", "newDeposit"],
  //   receipt: false,
  // },
  // {
  //   gateway: "PRAXIS_PayMaxisAPM_GATEWAY",
  //   name: "PayMaxisAPM - Finitic",
  //   image: "/img/payment-method/PayAPM.png",
  //   allowed:  ["mt5Deposit", "fiatDeposit", "cryptoDeposit", "newDeposit"],
  //   receipt: false,
  // },
  {
    gateway: "FINITIC_PAY",
    name: "Finitic Pay",
    image: usdt,
    allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit", "newDeposit"],
    receipt: false,
  },

];


export const allowedMethodsLocal =  [
 /*  {
    gateway: "حواله بنكيه",
    name: "حواله بنكيه",
    image: "/img/payment-method/8.png",
    allowed:  ["mt5Deposit", "fiatDeposit", "cryptoDeposit", "newDeposit"],
    receipt: true,
  },
  {
    gateway: "WESTERN_UNION",
    name: "WESTERN_UNION",
    image: "/img/payment-method/7.png",
    allowed:  ["mt5Deposit", "fiatDeposit", "cryptoDeposit", "newDeposit"],
    receipt: true,
  },
  {
    gateway: "PAYEER",
    name: "PAYEER",
    image: "/img/payment-method/6.png",
    allowed:  ["mt5Deposit", "fiatDeposit", "cryptoDeposit", "newDeposit"],
    receipt: true,
  }, */
];