import React, { useEffect, useState } from "react";
// eslint-disable-next-line object-curly-newline
import { Button, Col, Container, Row } from "reactstrap";
import MetaTags from "react-meta-tags";
import "./AccountOverview.scss";
import { useParams } from "react-router-dom";
//i18n
import { withTranslation } from "react-i18next";
import CardWrapper from "components/Common/CardWrapper";
import demoAvatar from "assets/images/demo-avatar.jpg";
import { ReactComponent as AccountDetailsIcon } from "assets/img/icons/account-details.svg";
import { ReactComponent as CopyIcon } from "assets/img/icons/copy-icon.svg";
import { ReactComponent as TradingCycleDetails } from "assets/img/icons/trading-cycle-details-icon.svg";
import AccountPrizeOverviewInfo from "./AccountOverviewInfo/AccountPrizeOverviewInfo";
import AccountOverviewStats from "./AccountOverviewStats/AccountOverviewStats";
// import AccountOverviewConsistencyHistory from "./AccountOverviewConsistencyHistory/AccountPrizeOverviewConsistencyHistory";
import AccountOverviewGrowth from "./AccountOverviewGrowth/AccountOverviewGrowth";
import AccountOverviewTradingObjective from "./AccountOverviewTradingObjective/AccountPrizeOverviewTradingObjective";
import { fetchChallenge } from "apis/challenges";
import { getAccountsDetailsAPI } from "apis/accounts";
import AccountPrizeOverviewConsistencyHistory from "./AccountOverviewConsistencyHistory/AccountPrizeOverviewConsistencyHistory";
function AccountPrizesOverview(props) {
  const { id } = useParams();
  const [challenge, setChallenge] = useState([]);
  useEffect(()=>{
      getAccountsDetailsAPI(id).then((res) => {
          console.log("resss", res);
          setChallenge(res?.result);
          // setLoading(false);
          // setResult(res);
          // toggleTab(1);
      }).catch((err) => {
          // setLoading(false);
          // setResult(err);
          // toggleTab(1);
      });
  }, []);
  return (
    <div className="page-content">
      <MetaTags>
        <title>{props.t("Account Overview")}</title>
      </MetaTags>
      <Container>
        <Row>
          <Col lg="12" className="my-5">
            <AccountPrizeOverviewInfo challenge={challenge}/>
          </Col>
          <Row>
            <Col lg="12" >
              <Row>
                {/* <Col lg="12"  className="mb-5">
                  <AccountOverviewStats  challenge={challenge}/>
                </Col>
                <Col lg="12"  className="mb-5">
                  <AccountOverviewTradingObjective  challenge={challenge}/>
                </Col> */}
                <Col lg="12"  className="mb-5">
                  <AccountPrizeOverviewConsistencyHistory  challenge={challenge}/>
                </Col>
              </Row>
            </Col>
            {/* <Col lg="3">
              <AccountOverviewGrowth  challenge={challenge}/>
            </Col> */}
          </Row>
        </Row>
      </Container>
    </div>
  );
}
export default withTranslation()(AccountPrizesOverview);
