import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleCurrentModal } from "../../../store/actions";
import TransferModal from "./Transfer";

function TransferPrizeModal({ prizeObject, accountId }) {
  const dispatch = useDispatch();
  const { currentModal } = useSelector((state) => ({
    currentModal: state.Layout.currentModal,
    modalData: state.Layout.modalData,
  }));
  return (
    <>
      {currentModal === "TransferPrizeModal" && <TransferModal
        isOpen={currentModal === "TransferPrizeModal"}
        toggleOpen={() => {
          dispatch(toggleCurrentModal(""));
        }}
        prizeObject={prizeObject}
        accountId={accountId}
      ></TransferModal>}

    </>
  );
}

export default TransferPrizeModal;
