import { FETCH_COMPETITIONS_START, FETCH_COMPETITIONS_SUCCESS } from "./actionTypes";

const initialState = {
  getCompetitions: {
    docs: [],
    page: 1,
    limit: 10,
    loading: false,
    error: ""
  }
};

const competitionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPETITIONS_START:
      return {
        ...state,
        getCompetitions: {
          ...state.getCompetitions,
          loading: true
        }
      };
    case FETCH_COMPETITIONS_SUCCESS:
      return {
        ...state,
        getCompetitions: {
          ...state.getCompetitions,
          docs: action.payload.payload,
          loading: false,
        }
      };
    default:
      return { ...state };
  }
};
export default competitionReducer;