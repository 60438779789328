import {
  REQUEST_IB_START,
  REQUEST_IB_SUCCESS,
  REQUEST_IB_FAIL,
  GET_REQUEST_IB_START,
  GET_REQUEST_IB_SUCCESS,
  GET_REQUEST_IB_FAIL,
  // VERIFY_TWO_FACTOR_CODE_FAIL,
  // VERIFY_TWO_FACTOR_CODE_START,
  // VERIFY_TWO_FACTOR_CODE_SUCCESS
} from "./actionTypes";

const initalState = {
  ib:{
    loading: false
  },
  // verifyCode: {
  //   loading: false
  // },
  // generateQR: {
  //   loading: false
  // },
};

const referralsReducer = (state = { ...initalState }, action)=>{
  switch (action.type){
    // fetch client deposits 
    case REQUEST_IB_START:
      state = {
        ...state,
        ib: {
          loading: true
        },
      };
      break;
    case REQUEST_IB_SUCCESS:
      state = {
        ...state,
        ib: {
          loading: false,
          success: true,
          // qrCode: action.payload
        },
      };
      break;
    case REQUEST_IB_FAIL:
      state = {
        ...state,
        ib: {
          loading: false,
          success: false,
          error: action.payload,
        },
      };
      break;
    case GET_REQUEST_IB_START:
      state = {
        ...state,
        ib: {
          loading: true
        },
      };
      break;
    case GET_REQUEST_IB_SUCCESS:
      state = {
        ...state,
        ib: {
          loading: false,
          success: true,
          data: action.payload
        },
      };
      break;
    case GET_REQUEST_IB_FAIL:
      state = {
        ...state,
        ib: {
          loading: false,
          success: false,
          error: action.payload,
        },
      };
      break;
    // case VERIFY_TWO_FACTOR_CODE_START:
    //   state = {
    //     ...state,
    //     verifyCode: {
    //       loading: true,
    //     }
    //   };
    //   break;
    // case VERIFY_TWO_FACTOR_CODE_SUCCESS:
    //   state = {
    //     ...state,
    //     verifyCode: {
    //       loading: false,
    //       success: true,
    //       result: action.payload,
    //       disabled: action.payload.type === "disable",
    //     },
    //     generateQR: {
    //       ...state.generateQR,
    //       qrCode: null,
    //     }
    //   };
    //   break;
    // case VERIFY_TWO_FACTOR_CODE_FAIL:
    //   state = {
    //     ...state,
    //     verifyCode: {         
    //       loading: false,
    //       success: false,
    //       error: action.payload
    //     }
    //   };
    //   break;
    default:
      state = { ...state };
    
  }
  return state;
};
export default referralsReducer;