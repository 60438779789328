import React, { useEffect } from "react";
// eslint-disable-next-line object-curly-newline
import "./AccountOverviewStats.scss";
//i18n
import { withTranslation } from "react-i18next";
import CardWrapper from "components/Common/CardWrapper";
import demoAvatar from "assets/images/demo-avatar.jpg";
import { ReactComponent as StatsIcon } from "assets/img/icons/stats-icon.svg";
import { ReactComponent as DollarIcon } from "assets/img/icons/dollar-icon.svg";
import { ReactComponent as TradingCycleDetails } from "assets/img/icons/trading-cycle-details-icon.svg";

function AccountOverviewStats(props) {
  const { challenge } = props;
  return (
    
    <CardWrapper className="mb-5 glass-card shadow account-stats__wrapper">
      <h2 className="account-details__card-title">
        <StatsIcon/>
        {props.t("Stats")}
      </h2>

        <div className="account-details__stats-items-wrapper">

          <div className="account-details__stats-item-wrapper">
            <div className="account-details__stats-item-icon-wrapper">
              <DollarIcon/>
            </div>
            <p className="account-details__stats-item-text">${(challenge?.profitClosedPositions?.balance)?.toFixed(2)}</p>
            <p className="account-details__stats-item-value">{props.t("Balance")}</p>
          </div>

          <div className="account-details__stats-item-wrapper">
            <div className="account-details__stats-item-icon-wrapper">
              <DollarIcon/>
            </div>
            <p className="account-details__stats-item-text">${(challenge?.profitClosedPositions?.pNL)?.toFixed(2)}</p>
            <p className="account-details__stats-item-value">{props.t("Profit/Loss")}</p>
          </div>

          <div className="account-details__stats-item-wrapper">
            <div className="account-details__stats-item-icon-wrapper">
              <DollarIcon/>
            </div>
            <p className="account-details__stats-item-text">${(challenge?.profitClosedPositions?.pNL)?.toFixed(2)}</p>
            <p className="account-details__stats-item-value">{props.t("Max Loss")}</p>
          </div>

          <div className="account-details__stats-item-wrapper">
            <div className="account-details__stats-item-icon-wrapper">
              <DollarIcon/>
            </div>
            <p className="account-details__stats-item-text">{challenge?.profitClosedPositions?.tradingDays}</p>
            <p className="account-details__stats-item-value">{props.t("Trading Days")}</p>
          </div>
        </div>

    </CardWrapper>
  );
}
export default withTranslation()(AccountOverviewStats);
