import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { AvField, AvForm } from "availity-reactstrap-validation";
import AvFieldSelecvt from "components/Common/AvFieldSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  createWalletTransfer,
  fetchWalletTransferStart,
  // createGoldTransfer,
  // fetchConversionRateStart,
  fetchWallets,
  getAccountTypesStart,
  getAccountsStart,
  showErrorNotification,
  transferPrizeStart,
  updateType,
} from "store/actions";
import Loader from "components/Common/Loader";
import { fetchConversionRateStart } from "store/general/conversionRates/actions";

export default function TransferModal({ isOpen, toggleOpen, prizeObject, accountId }) {

  const [from, setFrom] = React.useState(undefined);
  const [to, setTo] = React.useState(undefined);
  const [differentCurrency, setDifferentCurrency] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const [notAllowed, setNotAllowed] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  // const { wallets, loading: walletLoading, clearingCounter, transferLoading } = useSelector((state) => state?.walletReducer);
  const { submitting } = useSelector((state) => state?.accounts);
  // const [loading, setLoading] = useState(false);

  const handleSubmit = (e, values) => {
    const data = {
      // amount: values?.amount,
      accountId,
    };
    // if (amount > maxAmount) {
    //   dispatch(showErrorNotification("Sorry! Amount is greater than max amount"));
    //   return;
    // }
    dispatch(transferPrizeStart(data));
  };
  return (
    <Modal centered isOpen={isOpen} toggle={toggleOpen} >
      <ModalHeader className="d-flex flex-column gap-3" toggle={toggleOpen}>
        {t("Transfer Prize To Wallet")} 
      </ModalHeader>
      {
        (
          <ModalBody  style={{
            margin: "1rem"
          }}>
            <AvForm onValidSubmit={(e, v) => handleSubmit(e, v)} >
              {/* <div className="mb-3">
                <AvFieldSelecvt
                  name="from"
                  label={t("From")}
                  options={getFromOptions()}
                  value={from}
                  onChange={(e) => handleOnSelect(e, "from")}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "From is required"
                    },
                  }}
                />
              </div> */}
              {/* <div className="mb-3">
                <AvFieldSelecvt
                  name="to"
                  label={t("To")}
                  value={to}
                  options={getToOptions()}
                  onChange={(e) => handleOnSelect(e, "to")}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "To is required"
                    },
                  }}
                />
              </div> */}
              {/* {
                differentCurrency && (
                  conversionLoading ? <Loader/> : (
                    <div className="mb-3">
                      <Alert color="warning">
                        {t("You have selected different currencies. Conversion rate will be applied.")}
                        {` 1 ${from.currency} = ${parseFloat((conversionRate))?.toFixed(2)} ${to.currency}`}
                      </Alert>
                    </div>
                  )
                )
              } */}
              {/* {
                notAllowed && (
                    <div className="mb-3">
                      <Alert color="warning">
                        {t("Internal Transfer Is Not Allowed between Trading Accounts")}
                      </Alert>
                    </div>
                  )
              } */}
              <div className="mb-3">
                <Alert color="Unfo">
                  Amount Is {prizeObject?.netAmount}
                </Alert>
              </div>
              <div className="mb-3">
                <Alert color="Unfo">
                  Withdrawal Percentage Is {prizeObject?.withdrawedProfitValue * 100}%
                </Alert>
              </div>
              <div className="mb-3">
                <Alert color="Unfo">
                  Final Withdrawal Value Is {prizeObject?.finalAmount}
                </Alert>
              </div>
              {/* <div className="mb-3">
                <AvField
                  name="amount"
                  label="Amount"
                  type="number"
                  onChange={(e) => setAmount(e.target.value)}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Amount is required"
                    },
                    min: {
                      value: 1,
                      errorMessage: "Amount must be greater than 0"
                    },
                    max: {
                      value: maxAmount ?? 0,
                      errorMessage: `You don't have enough balance. Your balance is ${maxAmount}`
                    }
                  }}
                />
              </div> */}
              {/* <div className="mb-3">
                <AvField
                  name="note"
                  label={t("Note")}
                  type="text"
                />
              </div> */}
              {/* <div className="mb-3">
                {
                  (amount > 0 && to && from) && (
                    <Alert className="text-center">
                      {!differentCurrency ? `Total credit Amount: ${amount} ${from?.currency}` : `Total credit Amount: ${(amount * conversionRate)?.toFixed(3)} ${to?.currency}`}
                    </Alert>
                  )
                }
              </div> */}
              <div className="mb-3 text-center">
                {
                  submitting ? <Loader/> : (
                    <Button className="color-bg-btn border-0 w-75" type="submit">
                      {t("Submit")}
                    </Button>
                  )
                }
              </div>
            </AvForm>
          </ModalBody>
        )
      }
    </Modal>
  );
}
