import {
  takeEvery, fork, put, all, call
} from "redux-saga/effects";
import {
  REGISTER_LIVE_USER,
  REGISTER_FOREX_LIVE_USER_REQUESTED,
} from "./actionTypes";
import {
  registerLiveUserSuccessful,
  registerLiveUserFailed,

  registerForexLiveUserSuccessful,
  registerForexLiveUserFailed,
} from "./actions";
import { changePortal } from "../../actions";
import {
  registerClient,
} from "apis/register";
import { setUser } from "apis/api_helper";

function* registerLive({ payload }) {
  try {
    const { history } = payload.user;
    const result = yield call(registerClient, payload);
    if (result.message === "Data created  succesfull") {
      setUser(result.result);
      yield put(changePortal(result.result.defaultPortal));
      yield put(registerLiveUserSuccessful("User created successfully"));
      history.push("/dashboard");
    }
    if (result.isSuccess === false) {
      yield put(registerLiveUserFailed(result.message));
    }
  } catch (error) {
    yield put(registerLiveUserFailed(error));
  }
}

// forex live 
function* registerForexLiveUser({ payload }) {
  try {
    const { history } = payload.user;
    const result = yield call(registerClient, payload);
    if (result.status) {
      setUser(result.result);
      yield put(changePortal(result.result.defaultPortal));
      yield put(registerForexLiveUserSuccessful("User created successfully"));
      history.push("/dashboard");
    }
    if (!result.status) {
      yield put(registerForexLiveUserFailed(result.message));
    }
  } catch (error) {
    yield put(registerForexLiveUserFailed(error));
  }
}


export function* watchUserRegister() {
  yield takeEvery(REGISTER_LIVE_USER, registerLive);
  yield takeEvery(REGISTER_FOREX_LIVE_USER_REQUESTED, registerForexLiveUser);
}

function* accountSaga() {
  yield all([fork(watchUserRegister)]);
}

export default accountSaga;
