import React, { useEffect, useState } from "react";
// eslint-disable-next-line object-curly-newline
import MetaTags from "react-meta-tags";
// import { Card } from "react-bootstrap";
//i18n
import { withTranslation } from "react-i18next";
import "./Statistics.scss";
import { Link } from "react-router-dom";
import { 
    Col,
    Row,
    Card
} from "reactstrap";
import CardWrapper from "components/Common/CardWrapper";
import Spinearea from "components/ApexCharts/Spinearea";
function StatisticsCard({ title, number, description, link, linkText }) {
    return (
        <Card className="statistics__card">
            <h2 className="statistics__card-title">{title}</h2>
            <p className="statistics__card-number">{number}</p>
            <p className="statistics__card-description">{description}</p>
            <Link to={link} className="statistics__card-link">{linkText}</Link>
        </Card>
    );
}

function StatisticsReferalsCard({ title, number, description, link, linkText }) {
    return (
        <Card className="statistics-referrals">
            <div className="statistics-referrals__title-wrapper"> 
                <h2 className="statistics-referrals__title">Referrals</h2>
            </div>
            <div className="statistics-referrals__clients-wrapper">
                <div className="statistics-referrals__client-wrapper">
                    <div className="statistics-referrals__client-info-wrapper">
                        <p className="statistics-referrals__client-info-name">Chris Friedkly</p>
                        <p className="statistics-referrals__client-info-city">Dubai, Egypt</p>
                    </div>
                    <div className="statistics-referrals__client-date-wrapper">
                        <p className="statistics-referrals__client-date-time">12 : 34 : PM</p>
                        <p className="statistics-referrals__client-date-day">13 - 04 - 2024</p>
                    </div>
                </div>
                <div className="statistics-referrals__client-wrapper">
                    <div className="statistics-referrals__client-info-wrapper">
                        <p className="statistics-referrals__client-info-name">Chris Friedkly</p>
                        <p className="statistics-referrals__client-info-city">Dubai, Egypt</p>
                    </div>
                    <div className="statistics-referrals__client-date-wrapper">
                        <p className="statistics-referrals__client-date-time">12 : 34 : PM</p>
                        <p className="statistics-referrals__client-date-day">13 - 04 - 2024</p>
                    </div>
                </div>
            </div>
            <Link to="/" className="statistics-referrals__client-all-referrals">All Referrals</Link>
        </Card>
    );
}

function Statistics(props) {
    const [currentTab, setCurrentTab] = useState("base");
    const handleSelect = (key) => {
        setCurrentTab(key);
    };
    return (
        <div className="page-content">
            <MetaTags>
                <title>{props.t("Statistics")}</title>
            </MetaTags>
            <div className="statistics">
                <Row>
                    <Col md="4" className="mb-3">
                        <StatisticsCard
                            title="Referral Bonuses Earned"
                            number="1,432"
                            description="Increase compared to last week"
                            link="/"
                            linkText="Referral Report"
                        />
                    </Col>
                    <Col md="4" className="mb-3">
                        <StatisticsCard
                            title="Total Earnings"
                            number="$5,522"
                            description="You referred 96 new students"
                            link="/"
                            linkText="Earnings Report"
                        />
                    </Col>
                    <Col md="4" className="mb-3">
                        <StatisticsCard
                            title="Upcoming Earnings"
                            number="$492"
                            description="Earnings on the way"
                            link="/"
                            linkText="See Reports"
                        />
                    </Col>
                    <Col md="6">
                        <StatisticsReferalsCard/>
                    </Col>
                    <Col md="6">
                        <Row>
                            <Col md="12" className="mb-3">
                                <Spinearea colors={["#665bff"]}/>
                            </Col>
                            <Col md="4">
                                <Card className="statistics__small-card">
                                    <h3 className="statistics__small-card-title">Top month</h3>
                                    <h2 className="statistics__small-card-value">November</h2>
                                    <p className="statistics__small-card-description">2019</p>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="statistics__small-card">
                                    <h3 className="statistics__small-card-title">Top Year</h3>
                                    <h2 className="statistics__small-card-value">2023</h2>
                                    <p className="statistics__small-card-description">96K Earned so far</p>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="statistics__small-card">
                                    <h3 className="statistics__small-card-title">Top Referrals</h3>
                                    <h2 className="statistics__small-card-value">Maggie Johnson</h2>
                                    <p className="statistics__small-card-description">Dubai, UAE</p>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
export default withTranslation()(Statistics);
