import React, { useEffect, useState } from "react";
// eslint-disable-next-line object-curly-newline
import { Button, Col, Container, Label, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import MetaTags from "react-meta-tags";

// import { fetchWallets, toggleCurrentModal } from "src/store/actions";
//i18n
import { withTranslation } from "react-i18next";
import CardWrapper from "components/Common/CardWrapper";
import CustomTable from "components/Common/CustomTable";
import { getAssetImgSrc } from "helpers/assetImgSrc";
import { fetchWallets, toggleCurrentModal } from "store/actions";
import Loader from "components/Common/Loader";
import { JClickHandler } from "components/Journey/handlers";
import "./CreateChallenge.scss";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { fetcPlansStart } from "store/plans/actions";
import FreePlanForm from "./FreePlanForm";
import ChoosePaymentMethod from "./ChoosePaymentMethod";
import Deposit from "components/Deposit/Deposit";
// import Deposit from "pages/Deposit/Deposit";

function CreateChallenge(props) {
  const dispatch = useDispatch();
  const [plan, setPlan] = useState({});
  const [challenge, setChallenge] = useState(0);
  const [tradingCapital, setTradingCapital] = useState("5000");
  const [plans, setPlans] = useState([]);
  const [isFree, setIsFree] = useState(false);
  const [whichStepPlan, setWhichStepPlan] = useState(1);
  const [isPaymentMethodActive, setIsPaymentMethodActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [planType, setPlanType] = useState("1");
  const [platform, setPlatform] = useState("MT4");
  const [preference, setPreference] = useState("swap");
  const [feature, setFeature] = useState("");
  const [features, setFeatures] = useState({});
  const plansSelector = useSelector(state => state?.planReducer?.plans);
  
  const { clientData } = useSelector((state) => state?.Profile);
  const { subPortal, portal } = useSelector((state) => ({
    subPortal: state.Layout.subPortal,
    portal: state.Layout.portal,
  }));
  
  const { currentModal } = useSelector((state) => ({
    currentModal: state.Layout.currentModal,
    modalData: state.Layout.modalData,
  }));
  const loadPlans = (page, limit) => {
    dispatch(
      fetcPlansStart({
        limit,
        page,
      })
    );
  };
  
  const handleChangePlan = (plan) => {
    setPlan(plan);
    // setIsFree(false);
  };

  const handleFeatuesChange = (name, checked) => {
    
    setFeatures({
      ...features,
      [name]: checked
    });
  };

  useEffect(() => {
    loadPlans(1, 100000);
  }, []);

  useEffect(() => {
    console.log("clientData", clientData, clientData?.hasFreeTrial);
  }, [clientData]);

  useEffect(() => {
    if (isFree) {
      setPlans(plansSelector?.filter(plan=> !plan?.isFree));
    } else {
      setPlans(plansSelector?.filter(plan=> (plan?.phases?.length === whichStepPlan && (!plan?.isFree))));
    }
  }, [plansSelector, whichStepPlan]);

  useEffect(() => {
    let featuresData = {};
    plan?.features?.forEach(feature=> {
      let booleanValue = Object.keys(featuresData)?.length > 0 ? false : true;
      featuresData = {
        ...featuresData,
        [feature._id]:booleanValue
      };
    });
    setFeatures(featuresData);
  }, [plan]);

  useEffect(()=>{
    setPlan(plans?.[0]);
    setPlatform(plans?.[0]?.platforms?.[0]);
    setFeature(plans?.[0]?.features?.[0]);
    plans?.[0]?.phases?.length > 0 && setPlanType(`${plans?.[0]?.phases?.length}`);
    plans?.[0]?.phases?.length > 0 && setPlanType(`${plans?.[0]?.phases?.length}`);
  }, [plans]);
  function toggleOpen(){
    dispatch(toggleCurrentModal(""));
    // setIsOpen(prevVal => !prevVal);
  }
  return (
    <div className="page-content">
      <MetaTags>
        <title>{props.t("Create")}</title>
      </MetaTags>
      <Container>
        {
          currentModal === "newDeposit" &&  <Deposit
            plan={plan}
            platform={platform}
            preference={preference}
            tradingCapital={tradingCapital}
            isOpen={currentModal === "newDeposit"} 
            type={currentModal}
            toggleOpen={toggleOpen} 
          />
        }
        {
          isPaymentMethodActive ? 
            <ChoosePaymentMethod
              plan={plan}
              planType={planType}
              platform={platform}
              preference={preference}
              tradingCapital={tradingCapital}
            />
          :
          <div className="my-5 create-challenge">
            <div className="challengs-top-bar-container mb-5">
              {/* {
                plans && plans?.map((planItem, index)=>(
                  <button key={planItem?._id} type="button" className={`challengs-top-bar-item ${(plan?._id === planItem?._id && !isFree) ? "challengs-top-bar-item--active" : ""}`} onClick={()=> handleChangePlan(planItem)}>
                    {planItem?.title}
                  </button>
                ))
              } */}
              <button type="button" className={`challengs-top-bar-item ${(!isFree && whichStepPlan === 1) ? "challengs-top-bar-item--active" : ""}`}  onClick={()=>{setIsFree(false);setWhichStepPlan(1)}}>
                One-Step {props.t("Plans")}
              </button>
              <button type="button" className={`challengs-top-bar-item ${(!isFree && whichStepPlan === 2) ? "challengs-top-bar-item--active" : ""}`}  onClick={()=>{setIsFree(false);setWhichStepPlan(2)}}>
                Two-Step {props.t("Plans")}
              </button>
              {
                !clientData?.hasFreeTrial &&
                  <button type="button" className={`challengs-top-bar-item ${isFree ? "challengs-top-bar-item--active" : ""}`}  onClick={()=>setIsFree(true)}>
                    {props.t("Free Trial")}
                  </button>
              }
            </div>
            <Row>
              {
                isFree ?
                  <FreePlanForm
                    plan={plan}
                    planType={planType}
                    platform={platform}
                    tradingCapital={tradingCapital}
                    preference={preference}
                    setPlanType={setPlanType}
                    setPlatform={setPlatform}
                    setTradingCapital={setTradingCapital}
                    setPreference={setPreference}
                    setIsFree={setIsFree}
                  />
                :
                  <>
                    <Col lg="6">
                      {
                        plans && plans?.map((planItem, index)=>(
                          <div key={index} className="create-challenge-plan-cards-wrapper">
                            <CardWrapper className={`glass-card shadow create-challenge-plan-card ${planItem?._id === plan?._id ? "create-challenge-plan-card--active" : ""}`}>
                              <button type="button" onClick={()=> handleChangePlan(planItem)} className="create-challenge-plan-cards-link"></button>
                              <div className="create-challenge-plan-check-wrapper"> 
                                {
                                  planItem?._id === plan?._id &&
                                    <FeatherIcon
                                      icon="check"
                                      className="icon-md"
                                      color="#665bff"
                                    />
                                }
                              </div>
                              <div>
                                <h2 className="create-challenge-plan-card-title">{planItem?.title}</h2>
                                <p className="create-challenge-plan-card-description">{planItem?.description}</p>
                              </div>
                              <div>
                                <p className="create-challenge-plan-card-price">${planItem?.initialBalance}</p>
                              </div>
                            </CardWrapper>
                          </div>
                        ))
                      }
                      <div className="create-challenge-plan__total-wrapper">
                        <h2 className="create-challenge-plan__total-title">{props.t("Your Total Price")}</h2>
                        <p className="create-challenge-plan__total-price">${plan?.price}</p>
                        {/* <button type="button" onClick={()=>setIsPaymentMethodActive(true)} className="create-challenge-plan__enroll">{props.t("Enroll Now")}</button> */}
                        <Button type="button" className="create-challenge-plan__enroll" onClick={() => {
                          JClickHandler("newDeposit", clientData?.stages, dispatch, toggleCurrentModal, subPortal, portal)();
                        }}>{props.t("Enroll Now")}</Button>
                        {/* <Button type="button" className="create-challenge-plan__enroll" onClick={() => {
                          JClickHandler("fiatDeposit", clientData?.stages, dispatch, toggleCurrentModal, subPortal, portal)();
                        }}>{props.t("Enroll Now")}</Button> */}
                        
                      </div>
                    </Col>
                    <Col lg="6">
                      <CardWrapper className="glass-card shadow create-challenge-plan-options-card">
                        <h2 className="create-challenge-plan-card-options-card-title">{props.t("Plan Type")}</h2>
                        <div className="create-challenge-plan-options-wrapper">
                          {plan?.phases?.length ? <div className="create-challenge-plan-option-wrapper create-challenge-plan-option-wrapper--active" onClick={()=>setPlanType(`${plan?.phases?.length}`)}>
                            <input 
                              type="checkbox"
                              className="create-challenge-plan-option-input"
                              name="planType"
                              value={`${plan?.phases?.length}`}
                              id="planTypeOneStep"
                              checked
                              onClick={()=>setPlanType(`${plan?.phases?.length}`)}
                            />
                            <Label htmlFor="planTypeOneStep" className="create-challenge-plan-option-label">{plan?.phases?.length}{props.t("-Step")}</Label>
                          </div>
                          : <></>
                          }
                        </div>
                        
                      </CardWrapper>
                      <CardWrapper className="glass-card shadow create-challenge-plan-options-card">
                        <h2 className="create-challenge-plan-card-options-card-title">{props.t("Your Platform")}</h2>
                        <div className="create-challenge-plan-options-wrapper">
                          {
                            plan?.platforms && plan?.platforms?.map((platformItem, index) => (
                              <div key={index} className={`create-challenge-plan-option-wrapper ${platform === platformItem ? "create-challenge-plan-option-wrapper--active" : ""}`} onClick={()=>setPlatform(platformItem)}>
                                <input 
                                  type="checkbox"
                                  className="create-challenge-plan-option-input"
                                  name="platform"
                                  value={platformItem}
                                  id={`platformMetaTrader${index}`}
                                  checked={platform === platformItem}
                                  onClick={()=>setPlatform(platformItem)}
                                />
                                <Label htmlFor={`platformMetaTrader${index}`} className="create-challenge-plan-option-label">{props.t(platformItem)}</Label>
                              </div>
                            ))
                          }
                        </div>
                        
                      </CardWrapper>

                      {/* <CardWrapper className="glass-card shadow create-challenge-plan-options-card">
                        <h2 className="create-challenge-plan-card-options-card-title">{props.t("Select Your Preference")}</h2>
                        <div className="create-challenge-plan-options-wrapper">
                          <div className={`create-challenge-plan-option-wrapper ${preference === "swap" ? "create-challenge-plan-option-wrapper--active" : ""}`} onClick={()=>setPreference("swap")}>
                          <div className={`create-challenge-plan-option-wrapper ${preference === "swap" ? "create-challenge-plan-option-wrapper--active" : ""}`}>
                            <input 
                              type="checkbox"
                              className="create-challenge-plan-option-input"
                              name="preference"
                              value="swap"
                              id="preferenceSwap"
                              checked='checked'
                              onClick={()=>setPreference("swap")}
                            />
                            <Label htmlFor="preferenceSwap" className="create-challenge-plan-option-label">{props.t("Swap")}</Label>
                          </div>
                          <div className="create-challenge-plan-option-wrapper" onClick={()=>setPreference("swapFree")}>
                          <div className="create-challenge-plan-option-wrapper">
                            <input 
                              type="checkbox"
                              className="create-challenge-plan-option-input"
                              name="preference"
                              value="swapFree"
                              id="preferenceSwapFree"
                              checked={preference === "swapFree"}
                              onClick={()=>setPreference("swapFree")}
                            />
                            <Label htmlFor="preferenceSwapFree" className="create-challenge-plan-option-label">{props.t("Swap Free(Price+10%)")}</Label>
                          </div>
                        </div>
                      </CardWrapper> */}
                      
                      {plan?.phases?.map((phase, index)=>(
                        <>
                          <CardWrapper className="glass-card shadow create-challenge-plan-options-card">
                            <h2 className="create-challenge-plan-card-options-card-title">Phase-{index + 1}</h2>
                            <div className="create-challenge-plan-options-wrapper">
                              <div className={`create-challenge-plan-option-wrapper ${"create-challenge-plan-option-wrapper--active"}`}>
                                <Label htmlFor="preferenceSwap" className="create-challenge-plan-option-label">{props.t("Daily Loss")} : {phase?.targetDailyLoss}</Label>
                              </div>
                              <div className={`create-challenge-plan-option-wrapper ${"create-challenge-plan-option-wrapper--active"}`}>
                                <Label htmlFor="preferenceSwap" className="create-challenge-plan-option-label">{props.t("Max Loss")} : {phase?.targetMaxLoss}</Label>
                              </div>
                              <div className={`create-challenge-plan-option-wrapper ${"create-challenge-plan-option-wrapper--active"}`}>
                                <Label htmlFor="preferenceSwap" className="create-challenge-plan-option-label">{props.t("Profit")} : {phase?.targetProfitLoss}</Label>
                              </div>
                              <div className={`create-challenge-plan-option-wrapper ${"create-challenge-plan-option-wrapper--active"}`}>
                                <Label htmlFor="preferenceSwap" className="create-challenge-plan-option-label">{props.t("Min Trading Days")} : {phase?.targetMinTradingDays}</Label>
                              </div>
                              <div className={`create-challenge-plan-option-wrapper ${"create-challenge-plan-option-wrapper--active"}`}>
                                <Label htmlFor="preferenceSwap" className="create-challenge-plan-option-label">{props.t("Max Trading Days")} : {phase?.targetMaxTradingDays}</Label>
                              </div>
                              
                            </div>
                          </CardWrapper>
                          {/* <CardWrapper className="glass-card shadow create-challenge-plan-options-card">
                            <h2 className="create-challenge-plan-card-options-card-title">Phase-{index + 1}-{props.t("Max Loss")}</h2>
                            <div className="create-challenge-plan-options-wrapper">
                              <div className={`create-challenge-plan-option-wrapper ${"create-challenge-plan-option-wrapper--active"}`}>
                                <Label htmlFor="preferenceSwap" className="create-challenge-plan-option-label">{props.t("Max Loss")} : {phase?.targetMaxLoss} %</Label>
                              </div>
                            </div>
                          </CardWrapper> */}
                          {/* <CardWrapper className="glass-card shadow create-challenge-plan-options-card">
                            <h2 className="create-challenge-plan-card-options-card-title">Phase-{index + 1}-{props.t("Min Trading Days")}</h2>
                            <div className="create-challenge-plan-options-wrapper">
                              <div className={`create-challenge-plan-option-wrapper ${"create-challenge-plan-option-wrapper--active"}`}>
                                <Label htmlFor="preferenceSwap" className="create-challenge-plan-option-label">{props.t("Min Trading Days")} : {phase?.targetMinTradingDays}</Label>
                              </div>
                            </div>
                          </CardWrapper> */}
                          {/* <CardWrapper className="glass-card shadow create-challenge-plan-options-card">
                            <h2 className="create-challenge-plan-card-options-card-title">Phase-{index + 1}-{props.t("Max Trading Days")}</h2>
                            <div className="create-challenge-plan-options-wrapper">
                              <div className={`create-challenge-plan-option-wrapper ${"create-challenge-plan-option-wrapper--active"}`}>
                                <Label htmlFor="preferenceSwap" className="create-challenge-plan-option-label">{props.t("Max Trading Days")} : {phase?.targetMaxTradingDays}</Label>
                              </div>
                            </div>
                          </CardWrapper> */}
                          {/* <CardWrapper className="glass-card shadow create-challenge-plan-options-card">
                            <h2 className="create-challenge-plan-card-options-card-title">Phase-{index + 1}-{props.t("Profit Loss")}</h2>
                            <div className="create-challenge-plan-options-wrapper">
                              <div className={`create-challenge-plan-option-wrapper ${"create-challenge-plan-option-wrapper--active"}`}>
                                <Label htmlFor="preferenceSwap" className="create-challenge-plan-option-label">{phase?.targetProfitLoss}</Label>
                              </div>
                            </div>
                          </CardWrapper> */}
                        </>
                      ))}

                      {/* <CardWrapper className="glass-card shadow create-challenge-plan-options-card">
                        <h2 className="create-challenge-plan-card-options-card-title">{props.t("Select Add-on")}</h2>
                        <div className="create-challenge-plan-options-wrapper flex-columns gap-2">
                          {
                            plan?.features && plan?.features?.map((featureItem, index) => (
                              <div key={index} className={`create-challenge-plan-option-wrapper w-100 ${(features?.[featureItem?._id] || false) ? "create-challenge-plan-option-wrapper--active" : ""}`} onClick={(e)=>handleFeatuesChange(featureItem?._id, !features?.[featureItem?._id] )}>
                                <input 
                                  type="checkbox"
                                  className="create-challenge-plan-option-input create-challenge-plan-option-input--radius"
                                  name="feature"
                                  value={featureItem}
                                  id={`featureMetaTrader${index}`}
                                  checked={features?.[featureItem?._id] || false}
                                  onChange={(e)=>handleFeatuesChange(featureItem?._id, e.target.checked )}
                                />
                                <Label htmlFor={`featureMetaTrader${index}`} className="create-challenge-plan-option-label">{featureItem?.title}</Label>
                              </div>
                            ))
                          }
                        </div>
                      </CardWrapper> */}
                    </Col>
                  </>
              }
            </Row>
          </div>
        }
      </Container>
    </div>
  );
}
export default withTranslation()(CreateChallenge);
