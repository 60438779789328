import {
  Suspense,
  useEffect,
} from "react";
import {
  useSelector,
  useDispatch,
} from "react-redux";
import { Switch, BrowserRouter as Router } from "react-router-dom";

import SocketProvider from "./context";

import Authmiddleware from "./routes/middleware/Authmiddleware";

import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import AppLoader from "./components/AppLoader";

import routes from "./routes/routes";
import { IS_IOS } from "common/constants";

import "./assets/scss/preloader.scss";
import "./assets/scss/theme.scss";
import { receivedNotification, updatePushNotificationOption } from "store/actions";
import { checkPushNotificationSubscription } from "serviceWorkerRegistration";
import formatReceivedNotification from "helpers/formatReceivedNotification";


const lang = localStorage.getItem("I18N_LANGUAGE");
if (lang === "ar") {
  import("./assets/scss/theme-rtl.scss");
}

const channel = new BroadcastChannel("notification--exinitic--messages");

const App = () => {
  const dispatch = useDispatch();
  const notification = (!IS_IOS && Notification?.permission) || null;

  channel.onmessage = (event) => {
    const formattedData = formatReceivedNotification(event.data);
    if (formattedData._id) {
      dispatch(receivedNotification(formattedData));
    }
  };

  useEffect(async () => {
    const checkCurrentNotification = await checkPushNotificationSubscription();
    dispatch(updatePushNotificationOption(checkCurrentNotification));
  }, [notification]);

  return (
    <SocketProvider>
      <Suspense fallback={<AppLoader />}>
        <Router>
          <Switch>
            {routes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={route.isAuth ? VerticalLayout : NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={route.isAuth ? true : false}
                exact
              />
            ))}
          </Switch>
        </Router>
      </Suspense>
    </SocketProvider>
  );
};
export default App;