import React, { useEffect } from "react";
// eslint-disable-next-line object-curly-newline
import "./AccountOverviewTradingObjective.scss";
//i18n
import { withTranslation } from "react-i18next";
import CardWrapper from "components/Common/CardWrapper";
import demoAvatar from "assets/images/demo-avatar.jpg";
import { ReactComponent as StatsIcon } from "assets/img/icons/stats-icon.svg";
import { ReactComponent as DollarIcon } from "assets/img/icons/dollar-icon.svg";
import { ReactComponent as TradingObjective } from "assets/img/icons/trading-objective.svg";
function AccountOverviewTradingObjective(props) {
  const {challenge} = props;
  return (
    
    <CardWrapper className="mb-5 glass-card shadow account-trading__wrapper">

      <h2 className="account-details__card-title">
        <TradingObjective/>
        {props.t("Trading Objective")}
      </h2>

        <div className="account-trading__items-wrapper">

          <div className="account-trading__item-wrapper">
            <div className="account-trading__item-icon-wrapper">
              <DollarIcon/>
            </div>
            <div className="account-trading__item-content-wrapper">
              <h2 className="account-trading__item-content-title">{props.t("Daily Loss Limit")}</h2>
              <p>{props.t("Max Loss Limit")} ${challenge?.dailyLoss}</p>
              <p>{props.t("Today's Permitted Loss")} : ${(challenge?.profitClosedPositions?.pNLDaily)?.toFixed(2)}</p>
            </div>
            <p className="account-trading__item-status-wrapper">
              <span className="account-trading__item-status-color"></span>
              {props.t("Ongoing")}
            </p>
          </div>

          <div className="account-trading__item-wrapper">
            <div className="account-trading__item-icon-wrapper">
              <DollarIcon/>
            </div>
            <div className="account-trading__item-content-wrapper">
              <h2 className="account-trading__item-content-title">{props.t("Overall Loss Limit")}</h2>
              <p>{props.t("Max Loss Limit")} : ${challenge?.maxLoss}</p>
              <p>{props.t("Max Permitted Loss")} : ${(challenge?.profitClosedPositions?.pNL)?.toFixed(2)}</p>
            </div>
            <p className="account-trading__item-status-wrapper">
              <span className="account-trading__item-status-color"></span>
              {props.t("Ongoing")}
            </p>
          </div>

          <div className="account-trading__item-wrapper">
            <div className="account-trading__item-icon-wrapper">
              <DollarIcon/>
            </div>
            <div className="account-trading__item-content-wrapper">
              <h2 className="account-trading__item-content-title">{props.t("Minimum Trading Days")}</h2>
              <p>{props.t("Minimum")} : {challenge?.minTradingDays}</p>
              <p>{props.t("Current Result")} : {challenge?.profitClosedPositions?.tradingDays}</p>
            </div>
            <p className="account-trading__item-status-wrapper">
              <span className="account-trading__item-status-color"></span>
              {props.t("Ongoing")}
            </p>
          </div>

          <div className="account-trading__item-wrapper">
            <div className="account-trading__item-icon-wrapper">
              <DollarIcon/>
            </div>
            <div className="account-trading__item-content-wrapper">
              <h2 className="account-trading__item-content-title">{props.t("Profit Target")}</h2>
              <p>{props.t("Minimum")} : ${challenge?.targetProfit}</p>
              <p>{props.t("Current Result")} : ${challenge?.currentProfit}</p>
            </div>
            <p className="account-trading__item-status-wrapper">
              <span className="account-trading__item-status-color"></span>
              {props.t("Ongoing")}
            </p>
          </div>

        </div>

    </CardWrapper>
  );
}
export default withTranslation()(AccountOverviewTradingObjective);
