import { all, fork } from "redux-saga/effects";

import generalSaga from "./general/sagas";
import walletSaga from "./wallets/saga";
import PlanSaga from "./plans/saga";
import AccountsSaga from "./accounts/saga";

export default function* rootSaga() {
  yield all([
    fork(AccountsSaga),
    fork(PlanSaga),
    fork(generalSaga),
    fork(walletSaga),
  ]);
}
