// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ResetPassword from "pages/Authentication/ResetPassword";
import Register from "pages/Authentication/Register/Register";
import Page404 from "../pages/Authentication/Page404";

export default [
  //non Auth routes
  {
    path: "/logout",
    component: Logout,
    isAuth: false
  },
  {
    path: "/login",
    component: Login,
    isAuth: false
  },
  {
    path: "/forgot-password",
    component: ForgetPwd,
    isAuth: false
  },
  {
    path: "/register",
    component: Register,
    isAuth: false
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    isAuth: false
  },
  {
    path: "*",
    exact: true,
    component: Page404,
    isAuth: false
  },
];