import {  FETCH_COMPETITIONS_START } from "./actionTypes";
import {
  takeEvery, call, fork, all, put 
} from "redux-saga/effects";
import { getCompetitions } from "apis/competitions";
import { fetchCompetitionsSuccess, fetchCompetitionsError } from "./actions";

function* fetchCompetitions({ payload }) {
  try {
    const result = yield call(getCompetitions, payload);
    yield put(fetchCompetitionsSuccess({
      success: true,
      payload: result
    }));
  } catch (error) {
    yield put(fetchCompetitionsError({
      success: false,
      message: error.message
    }));
  }
}

function* watchConversion() {
  yield takeEvery(FETCH_COMPETITIONS_START, fetchCompetitions);
}

function* competitionSaga() {
  yield all([fork(watchConversion)]);
}

export default competitionSaga;