import { CUSTOMER_SUB_PORTALS } from "common/constants";
import dashboardSvg from "./icons/dashboardSvg";
import dotSvg from "./icons/dotSvg";
import accountSvg from "./icons/accountSvg";
import walletSvg from "./icons/walletSvg";
import moneyBagSvg from "./icons/moneyBagSvg";
import depositSvg from "./icons/depositSvg";
import withdrawSvg from "./icons/withdrawSvg";
import transferSvg from "./icons/transferSvg";
import platformSvg from "./icons/platformSvg";
import webTraderSvg from "./icons/webTraderSvg";
import partnershipSvg from "./icons/partnershipSvg";
import reportSvg from "./icons/reportSvg";
import affiliateSvg from "./icons/affiliateSvg";
import profileSvg from "./icons/profileSvg";
import ibTransferSvg from "./icons/ibTransferSvg";
import documentSvg from "./icons/documentSvg";
import inventorySvg from "./icons/inventorySvg";
import historySvg from "./icons/historySvg";
import masterSvg from "./icons/masterSvg";
import investorSvg from "./icons/investorSvg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faEnvelope, faChartSimple, faBookOpen, faCertificate, faDollar, faMoneyBill1Wave, faUsers, faFileUpload, faGear, faLayerGroup
} from "@fortawesome/free-solid-svg-icons";

const lightFill = "#395B77";
const darkFill = "#fff";
export default (layoutMode) => {
  let menu = [
    {
      title: "Dashboard",
      icon: dashboardSvg({
        color: layoutMode === "dark" ? darkFill : lightFill,
        fill: layoutMode === "dark" ? "#0A172D" : "#fff",
      }),
      link: "/dashboard",
    },
    {
      title: "Plans",
      icon: <FontAwesomeIcon icon={faLayerGroup} />,
      link: "/plans/create",
    },
    // {
    //   title: "Account Overview",
    //   icon: <FontAwesomeIcon icon={faUsers} />,
    //   link: "/accounts/overview",
    // },
    // {
    //   title: "Statistics",
    //   icon: <FontAwesomeIcon icon={faChartSimple} />,
    //   link: "/statistics",
    // },
    {
      title: "Wallets",
      icon: <FontAwesomeIcon icon={faDollar} />,
      link: "/wallet",
    },
    {
      title: "Certificate",
      icon: dashboardSvg({
        color: layoutMode === "dark" ? darkFill : lightFill,
        fill: layoutMode === "dark" ? "#0A172D" : "#fff",
      }),
      link: "/certificates"
    },
    // {
    //   title: "Trading Tools",
    //   icon: <FontAwesomeIcon icon={faTools} />,
    //   link: "/trading-tools",
    // },
    {
      title: "Documents",
      icon: <FontAwesomeIcon icon={faFileUpload} />,
      link: "/documents",
    },
    {
      title: "Billings",
      icon: <FontAwesomeIcon icon={faMoneyBill1Wave} />,
      link: "/billings",
    },
    // {
    //   title: "Prizes",
    //   icon: <FontAwesomeIcon icon={faMoneyBill1Wave} />,
    //   link: "/prizes",
    // },
    // {
    //   title: "Refer a Friend",
    //   icon: <FontAwesomeIcon icon={faUsers} />,
    //   link: "/refer-friend",
    // },
    {
      title: "Platforms",
      icon: <FontAwesomeIcon icon={faEnvelope} />,
      link: "/platforms",
    },
    // {
    //   title: "Settings",
    //   icon: <FontAwesomeIcon icon={faGear} />,
    //   link: "/settings",
    // },
    {
      title: "My Profile",
      icon: profileSvg({ fill: layoutMode === "dark" ? darkFill : lightFill }),
      link: "#",
      hasSubMenu: true,
      subMenu: [
        {
          title: "User Profile",
          icon: dotSvg({ fill: layoutMode === "dark" ? darkFill : lightFill }),
          link: "/profile",
          hasSubMenu: false,
          subMenu: [],
        },
        // {
        //   title: "My Addresses",
        //   icon: dotSvg({ fill: layoutMode === "dark" ? darkFill : lightFill }),
        //   link: "/addresses",
        //   hasSubMenu: false,
        //   subMenu: [],
        // },
        {
          title: "Bank Accounts",
          icon: dotSvg({ fill: layoutMode === "dark" ? darkFill : lightFill }),
          link: "/bank-accounts",
          hasSubMenu: false,
          subMenu: [],
        },
        // {
        //   title: "Activities",
        //   icon: dotSvg({ fill: layoutMode === "dark" ? darkFill : lightFill }),
        //   link: "/activities",
        //   hasSubMenu: false,
        //   subMenu: [],
        // },
        // {
        //   title: "Notifications",
        //   icon: dotSvg({ fill: layoutMode === "dark" ? darkFill : lightFill }),
        //   link: "/notifications",
        //   hasSubMenu: false,
        //   subMenu: [],
        // },
        {
          title: "2FA",
          icon: dotSvg({ fill: layoutMode === "dark" ? darkFill : lightFill }),
          link: "/two-fa",
          hasSubMenu: false,
          subMenu: [],
        },
        // {
        //   title: "Referrals",
        //   icon: dotSvg({ fill: layoutMode === "dark" ? darkFill : lightFill }),
        //   link: "/referrals",
        //   hasSubMenu: false,
        //   subMenu: [],
        // },
      ],
    }
  ];
  return menu;
};