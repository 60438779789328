import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleCurrentModal } from "../../store/actions";
import AddBankAccountModal from "../BankAccounts/AddBankAccountModal";
import CryptoDeposit from "../Deposit/Crypto/CryptoDeposit";
import CryptoWithdraw from "../Withdraw/Crypto/CryptoWithdraw";

function CryptoModals() {
  const dispatch = useDispatch();
  const { currentModal, modalData } = useSelector((state) => ({
    currentModal: state.Layout.currentModal,
    modalData: state.Layout.modalData,
  }));
  return (
    <>
      {currentModal === "cryptoDeposit" && <CryptoDeposit
        isOpen={currentModal === "cryptoDeposit"}
        toggleOpen={() => {
          dispatch(toggleCurrentModal(""));
        }}
      ></CryptoDeposit>}
      {currentModal === "AddBankAccountModal" &&
      <AddBankAccountModal
        isOpen={currentModal === "AddBankAccountModal"}
        toggleOpen={() => {
          dispatch(toggleCurrentModal(""));
        }}
      ></AddBankAccountModal>}
      {currentModal === "cryptoWithdraw" &&   
        <CryptoWithdraw
          isOpen={currentModal === "cryptoWithdraw"}
          toggleOpen={() => {
            dispatch(toggleCurrentModal(""));
          }}
        ></CryptoWithdraw>}
    </>
  );
}

export default CryptoModals;
