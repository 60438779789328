import React, { useEffect, useState } from "react";
// eslint-disable-next-line object-curly-newline
import "./AccountOverviewGrowth.scss";
//i18n
import { withTranslation } from "react-i18next";
import CardWrapper from "components/Common/CardWrapper";
import chatLogo from "assets/img/fn-chat-logo.png";
import { ReactComponent as AccountDetailsIcon } from "assets/img/icons/account-details.svg";
import { ReactComponent as CopyIcon } from "assets/img/icons/copy-icon.svg";
import { ReactComponent as TradingCycleDetails } from "assets/img/icons/trading-cycle-details-icon.svg";
import { Link } from "react-router-dom";
import moment from "moment";
function AccountOverviewGrowth(props) {
  const { challenge } = props;
  const calculateTimeLeft = () => {
    const now = moment();
    const nextMidnight = moment().endOf("day").add(1, "second"); // Add 1 second to ensure it counts down to exactly midnight
    const startingDate = moment(challenge?.startingDate).endOf("day").add(1, "second");
    const endingDate = moment(challenge?.endingDate).endOf("day").add(1, "second");
    let duration;
    
    if (challenge?.startingDate && challenge?.endingDate) {
      const startingDate = moment(challenge.startingDate).endOf("day").add(1, "second");
      const endingDate = moment(challenge.endingDate).endOf("day").add(1, "second");
      
      console.log("endingDate", endingDate.isAfter(now), startingDate.isBefore(endingDate));
      if (endingDate.isAfter(now) && startingDate.isBefore(endingDate)) {
        duration = moment.duration(endingDate.diff(now));
      } else {
        // duration = moment.duration(nextMidnight.diff(now)); // Fallback to next midnight
      }
    } else {
      // duration = moment.duration(nextMidnight.diff(now)); // Fallback to next midnight
    }
    console.log("duration", duration, duration?.asMilliseconds(), duration?.hours(), duration?.minutes(), duration?.seconds());
    if (duration) {
      return {
        time: duration?.asMilliseconds(),
        days: duration?.days(),
        hours: duration?.hours(),
        minutes: duration?.minutes(),
        seconds: duration?.seconds(),
      };
    }
    return {};
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    if (challenge?.dateType === "custom") {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
  
      return () => clearInterval(timer);
    }
  }, [challenge]);
  return (
    
    <CardWrapper className="mb-5 glass-card shadow account-growing__wrapper h-auto">
      <div className="account-growing__info-wrapper">
        <div className="account-growing__growth-wrapper">
          <p className="account-growing__growth-title">{props.t("Account Growth")}</p>
          <p className="account-growing__growth-price">{challenge?.accountGrowth}%</p>
        </div>

        <div className="account-growing__email-wrapper">
          <h3 className="account-growing__email-title">{props.t("Email Support")}</h3>
          {/* <img 
            src={chatLogo}
            className="account-growing__email-img"
            alt="Chat logo"
          /> */}
          <button className="account-growing__email-button">{props.t("Contact")}</button>
          <Link to="/support@QMMFX.com" className="account-growing__email-link">support@QMMFX.com</Link>
        </div>

        <div className="account-growing__email-text-wrapper">
          <p className="account-growing__email-text">
            {props.t("Today’s permitted loss will reset in")}
          </p>
          {
            (challenge?.dateType === "custom" && timeLeft?.time > 0) &&
              <div className="account-growing__email-timer-wrapper">
              {`${String(timeLeft.days).padStart(2, "0")}:${String(timeLeft.hours).padStart(2, "0")}:${String(timeLeft.minutes).padStart(2, "0")}:${String(timeLeft.seconds).padStart(2, "0")}`}
                {/* 08:53:22 */}
              </div>
          }
          {/* <p className="account-growing__email-timezone">
            {props.t("Countdown Timezone: GMT+3")}
          </p> */}
        </div>
      </div>
    </CardWrapper>
  );
}
export default withTranslation()(AccountOverviewGrowth);
