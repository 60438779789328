import { getIbRequestAPI, requestIbAPI } from "apis/auth";
// eslint-disable-next-line object-curly-newline
import { call, put, takeEvery } from "redux-saga/effects";
import { toggleCurrentModal } from "store/actions";
import {
  showErrorNotification,
  showSuccessNotification,
} from "store/general/notifications/actions";
import {
  // generateQRCodeSuccess,
  // verify2FACodeFail,
  // verify2FACodeSuccess,
  // generateQRCodeError,
  requestIbSuccess,
  requestIbFail,
  getRequestIbFail,
  getRequestIbSuccess,
} from "./actions";

import {
  GET_REQUEST_IB_START,
  // GENERATE_QR_CODE_START,
  REQUEST_IB_START,
  // VERIFY_TWO_FACTOR_CODE_START,
} from "./actionTypes";

function* requestIb(params) {
  try {
    const data = yield call(requestIbAPI, params.payload);
    const { status, result } = data;
    if (status) yield put(requestIbSuccess(result));
    yield put(showSuccessNotification("Request Sent succcessfully"));
  } catch (error) {
    yield put(requestIbFail(error.message));
    yield put(showErrorNotification(error.message));
  }
}

function* getRequestIb(params) {
  try {
    const data = yield call(getIbRequestAPI, params.payload);
    const { status, result } = data;
    if (status) yield put(getRequestIbSuccess(result));
    // yield put(showSuccessNotification("Request Ib Sent succcessfully"));
  } catch (error) {
    yield put(getRequestIbFail(error.message));
    yield put(showErrorNotification(error.message));
  }
}

// function* verifyCode({ payload }) {
//   try {
//     const { history, type } = payload;
//     delete payload.history;
//     const data = yield call(verifyCodeAPI, payload);
//     const { result, status } = data;
//     if (type === "login") {
//       if (status && result.token) {
//         localStorage.setItem("authUser", JSON.stringify(result));
//         yield put(verify2FACodeSuccess(data));
//         yield put(toggleCurrentModal(""));
//         history.push("/dashboard");
//       }
//     } else {
//       yield put(
//         verify2FACodeSuccess({
//           ...data,
//           type,
//         })
//       );
//       yield put(showSuccessNotification("Verified succcessfully"));
//     }
//   } catch (error) {
//     yield put(verify2FACodeFail(error.message));
//     yield put(showErrorNotification(error.message));
//   }
// }

function* referralsSaga() {
  yield takeEvery(REQUEST_IB_START, requestIb);
  yield takeEvery(GET_REQUEST_IB_START, getRequestIb);
  // yield takeEvery(VERIFY_TWO_FACTOR_CODE_START, verifyCode);
}

export default referralsSaga;
