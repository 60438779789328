import { Redirect } from "react-router-dom";
import Accounts from "pages/Forex/Accounts";
import Login from "pages/Authentication/Login";
import ChangePassword from "pages/Forex/ChangePassword";
import Withdraw from "pages/Forex/Withdraw";
import Deposit from "pages/Forex/Deposit";

// my profile menu
import Profile from "pages/Authentication/Profile";
import Activites from "pages/Forex/Activites";
import Documents from "pages/Documents";
import Applications from "pages/Forex/Applications/";
import nonAuthRoutes from "./nonAuthRoutes";
import BankAccounts from "pages/Forex/BankAccounts/BankAccounts";
import TwoFA from "../pages/Authentication/2FA";
import Wallet from "pages/Wallet";
import Notifications from "pages/Authentication/Notifications";
import Leaderboard from "pages/Forex/Competitions/Leaderboard";
import CompetitionDetails from "pages/Forex/Competitions/CompetitionDetails";
import Dashboard from "pages/Dashboard";
import MyChallenges from "pages/MyChallenges/MyChallenges";
import CreateChallenge from "pages/CreateChallenge/CreateChallenge";
import ChoosePaymentMethod from "pages/CreateChallenge/ChoosePaymentMethod";
import AccountOverview from "pages/Accounts/AccountOverview";
import AccountPrizesOverview from "pages/PrizesOverview/AccountPrizesOverview";
import Certificates from "pages/Certificates/Certificates";
import Billings from "pages/Billings";
import Referrals from "pages/Authentication/Referrals";
import ReferAFriend from "pages/ReferAFriend";
import Platforms from "../pages/Platforms";
import Prizes from "pages/Prizes";

export default [
  {
    path: "/wallet",
    component: Wallet,
    isAuth: true
  },
  {
    path: "/accounts/overview/:id",
    component: AccountOverview,
    isAuth: true,
  },
  {
    path: "/prizes/accounts/overview/:id",
    component: AccountPrizesOverview,
    isAuth: true,
  },
  {
    path: "/accounts/password",
    component: ChangePassword,
    isAuth: true,
  },
  {
    path: "/accounts/:type",
    component: Accounts,
    isAuth: true,
  },
  {
    path: "/deposit",
    component: Deposit,
    isAuth: true,
  },
  {
    path: "/profile",
    component: Profile,
    isAuth: true,
  },
  {
    path: "/activites",
    component: Activites,
    isAuth: true,
  },
  {
    path: "/documents",
    component: Documents,
    isAuth: true,
  },
  {
    path: "/application",
    component: Applications,
    exact: true,
    isAuth: true,
  },
  {
    path: "/withdraw",
    component: Withdraw,
    isAuth: true,
  },
  {
    path: "/login",
    component: Login,
    exact: true,
    isAuth: false,
  },
  {
    path: "/bank-accounts",
    component: BankAccounts,
    exact: true,
    isAuth: true
  },
  {
    path: "/two-fa",
    component: TwoFA,
    isAuth: true
  },
  {
    path: "/notifications",
    component: Notifications,
    isAuth: true
  },
  {
    path: "/billings",
    component: Billings,
    isAuth: true
  },
  {
    path: "/competition/leaderboard",
    component: Leaderboard,
    isAuth: true
  },
  {
    path: "/competition/details",
    component: CompetitionDetails,
    isAuth: true
  },

  {
    path: "/my-challenges",
    component: MyChallenges,
    isAuth: true,
  },
  {
    path: "/plans/create",
    component: CreateChallenge,
    isAuth: true,
  },
  {
    path: "/certificates",
    component: Certificates,
    isAuth: true,
  },
  {
    path: "/payment/:id",
    component: ChoosePaymentMethod,
    isAuth: true,
  },
  {
    path: "/bank-accounts",
    component: BankAccounts,
    exact: true,
    isAuth: true
  },
  {
    path: "/referrals",
    component: Referrals,
    exact: true,
    isAuth: true
  },
  {
    path: "/platforms",
    component: Platforms,
    isAuth: true,
  },
  {
    path: "/refer-friend",
    component: ReferAFriend,
    exact: true,
    isAuth: true
  },
  {
    path: "/dashboard",
    component: Dashboard,
    isAuth: true,
  },
  {
    path: "/prizes",
    component: Prizes,
    isAuth: true,
  },
  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    isAuth: true,
    component: () => <Redirect to="/dashboard" />
  },
  ...nonAuthRoutes
];
